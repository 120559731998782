<template>
  <div class="app_box">
    <div class="app_css">
      <div
        :class="
          tab_or
            ? 'bottom_css'
            : !tab_or && identifyList.length > 0
            ? 'top_css'
            : 'bottom_css'
        "
      >
        <div class="input_css">
          <div
            class="cite_input_css"
            style="padding: 10px 0 0 0"
            v-if="cite_data_copy.id"
          >
            <!--  -->
            <div class="cite_css" v-if="cite_data_copy.text">
              <!-- -->
              <i class="el-icon-close cite_icon" @click="close_icon"></i>

              <div class="wenxian_li_s">
                <div
                  class="wenxian_li wenxian_txt_css"
                  style="color: rgb(181 181 181); line-height: 15px !important"
                >
                  <span class="wenxian_txt_css"
                    ><span class="li_tit" style="color: #bcbec2"
                      >需求类型：</span
                    >
                    {{ cite_data_copy.text.text_name }}
                    <!-- 中文书 -->
                  </span>
                </div>
                <div
                  class="wenxian_li"
                  style="color: rgb(181 181 181); line-height: 15px !important"
                >
                  <span class="wenxian_txt_css"
                    ><span class="li_tit" style="color: #bcbec2"
                      >需求标题：</span
                    >
                    {{ cite_data_copy.text.title }}
                    <!-- Significant_improvement_inCH4_N2_selectivity_achieved_through_ammonium_exchange_in_mordenite -->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <i
            class="el-icon-close close_css"
            v-if="textarea_txt"
            @click="close_icon()"
          ></i> -->

          <div class="input_css_pc">
            <el-input
              type="textarea"
              :rows="4"
              :placeholder="`可批量查询文献，每条需求以换行结束，需求示例如下：\nDOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477`"
              v-model="textarea_txt"
              class="textarea_css"
              ref="textareaInput"
              style="border: none"
              @input="adjustDivHeight"
            >
              <!-- class="textarea_css" -->
              <!-- <img
                slot="suffix"
                @click="lookup_sub"
                class="btn_icon_img"
                src="https://xcx.newacademic.net/video/icon/fasong_.png"
                alt=""
              /> -->
            </el-input>
            <div class="btn_css">
              <img
                @click="lookup_sub"
                class="btn_icon_img"
                src="https://xcx.newacademic.net/video/icon/fasong_.png"
                alt=""
              />
            </div>
          </div>
          <!-- <img
            class="btn_icon_img history_css"
            src="https://xcx.newacademic.net/video/icon/history_.png"
            alt=""
            @click="goHistory"
          /> -->
        </div>
      </div>
      <div
        class="data_css"
        :class="
          tab_or
            ? 'bottom_list_css'
            : !tab_or && identifyList.length > 0
            ? 'top_list_css'
            : 'bottom_list_css'
        "
      >
        <div
          class="identify_list_css"
          v-if="!tab_or && identifyList.length > 0"
        >
          <h2 class="tit_txt">识别结果</h2>
          <div class="identify_li_s">
            <div
              class="identify_li_css"
              v-for="(item, index) in identifyList"
              :key="index"
            >
              <van-swipe-cell>
                <div class="li_css">
                  <el-checkbox
                    class="checkbox_col"
                    v-model="item.checked"
                    :disabled="item.disabled"
                    @change="checked_change(index)"
                  ></el-checkbox>
                  <div class="li_box">
                    <div class="li_box_tag">
                      <el-tag size="medium" @click="edit_demand(item, index)">{{
                        item.name
                      }}</el-tag>
                      <el-tag size="medium" @click="priority_edit(index)">{{
                        item.priority
                      }}</el-tag>
                      <el-tag size="medium" @click="remarkEdit(item, index)">{{
                        item.remark.length > 0 ? "已备注" : "备注"
                      }}</el-tag>
                      <span
                        v-if="item.resultMsg == ' ✔ 提交成功'"
                        style="
                          color: green;
                          font-size: 12px;
                          display: inline-block;
                        "
                        >{{ item.resultMsg }}</span
                      >
                      <span
                        v-else
                        style="
                          color: red;
                          font-size: 12px;
                          display: inline-block;
                        "
                        >{{
                          item.resultMsg.length > 20
                            ? " ✘ 提交失败"
                            : item.resultMsg
                        }}</span
                      >
                    </div>
                    <div class="li_box_txt">
                      {{ item.text }}
                    </div>
                  </div>
                  <div class="li_btn">
                    <img
                      src="../../../assets/imgs/app/edit.png"
                      @click="edit_demand(item, index)"
                      alt=""
                    />
                    <img
                      src="../../../assets/imgs/app/arrow_left.png"
                      style="width: 21px; margin-left: 10px"
                      alt=""
                    />
                  </div>
                </div>
                <template #right>
                  <!-- <van-button square type="danger" text="删除" />
                  <van-button square type="primary" text="编辑" /> -->
                  <div class="right_css">
                    <div
                      class="right_btn"
                      style="background: #f56c6c"
                      @click="del_demand(item, index)"
                    >
                      删除
                    </div>
                    <div
                      class="right_btn"
                      style="
                        background: #67c23a;
                        border-start-end-radius: 5px;
                        border-end-end-radius: 5px;
                      "
                      @click="edit_demand(item, index)"
                    >
                      编辑
                    </div>
                  </div>
                </template>
              </van-swipe-cell>
            </div>
          </div>
          <div class="footer_submit" v-if="identifyList.length > 0">
            <div class="clear_result" @click="clear_result">清空结果</div>
            <div class="merge_result" @click="merge_demand">需求合并</div>
            <div class="sumbit_selected" @click="handleSelect">提交</div>
          </div>
        </div>
        <div
          class="ai_css"
          v-if="tab_or || (!tab_or && identifyList.length <= 0)"
        >
          <div
            class="message_list_css"
            id="myDiv"
            @scroll="onScroll"
            ref="myDiv"
            style="padding: 10px 20px"
            :style="myDivStyle"
          >
            <!--   :style="cite_data_copy.id ? 'height: calc(100vh - 320px);' : ''" -->
            <!--  @touchstart="onTouchStart"
            @touchend="onTouchEnd" -->
            <div
              class="down_css"
              v-loading="down_loading"
              element-loading-text="数据加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0)"
            ></div>
            <div v-for="(item, index) in messageData" :key="index">
              <div
                class="time_css"
                v-if="firstIndex_s(item.date_time) == index"
              >
                {{ item.date_time }}
              </div>
              <div
                class="message_li_css"
                :style="
                  item.problem_answer == 1 ? 'justify-content: flex-end;' : ''
                "
              >
                <img
                  style="
                    width: 40px;
                    height: 40px;
                    position: relative;
                    left: -5px;
                  "
                  v-if="item.problem_answer !== 1"
                  src="../../../assets/imgs/app/robot_icon.png"
                  alt=""
                />
                <div
                  class="msg_txt_"
                  :style="
                    item.problem_answer == 1
                      ? 'max-width: calc(100% - 50px);min-width: 70px'
                      : 'width: calc(100% - 50px);'
                  "
                >
                  <!--  -->
                  <div class="msg_tit" v-if="item.problem_answer !== 1">
                    <div class="msg_name">图书馆参考咨询员</div>
                    <!-- <div class="msg_btn">
                     <div class="css_type" @click="handoff(item, index)">
                        {{ item.type == 1 ? "参考咨询" : "文献咨询" }}
                      </div>
                      <img
                        src="../../../assets/imgs/app/iconPark-switch 1@1x.png"
                        alt=""
                        style="width: 22px; height: 18px"
                      />
                    </div> -->
                  </div>
                  <div
                    v-if="item.type == 2 && item.problem_answer == 2"
                    class="msg_txt_css"
                  >
                    <div
                      class="wenxian_"
                      v-if="
                        !item.text.text &&
                        !Array.isArray(item.text) &&
                        typeof item.text == 'object'
                      "
                    >
                      <!-- <div class="public_txt">
                       
                        问题识别为【文献咨询】，如需要进行【参考咨询】可点击问题下方的类型标签切换咨询类型。
                      </div> -->
                      <div class="literature_list_css">
                        <div
                          class="literature_li"
                          v-for="(val, key, index) in item.text"
                          :key="index"
                        >
                          <div class="li_text_css">
                            ---{{ val.type_name }}---
                          </div>
                          <div class="li_text_css">
                            <span v-html="val.text"></span
                            ><span
                              class="tip_css"
                              style="color: #f56c6c"
                              v-if="val.code !== 0"
                            >
                              ({{ val.msg }})
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="public_txt"
                        style="margin-top: 15px"
                        v-if="or_msg(item.text)"
                      >
                        这{{
                          or_msg(item.text)
                        }}篇文献我们正在加速查找中请您耐心等待，稍后会给您回复查找结果。若您觉得等待时间较长，也可以随时在【资源管理】列表中查看该任务的进度及最终处理结果~
                      </div>
                    </div>

                    <div
                      class="cite_css"
                      v-if="item.quote_id && result_data[item.quote_id]"
                    >
                      <div class="wenxian_li_s">
                        <div class="wenxian_li">
                          <span class="li_tit">需求类型：</span
                          >{{ result_data[item.quote_id].text.text_name }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">需求标题：</span
                          >{{ result_data[item.quote_id].text.title }}
                        </div>
                      </div>
                    </div>
                    <div class="wenxian_" v-if="Array.isArray(item.text)">
                      <div
                        class="public_txt"
                        style="font-weight: 600; color: #292929; margin: 5px 0"
                      >
                        您是否遇到了以下问题，可点击对应的问题进行反馈或查看常见解决办法
                      </div>
                      <div
                        class="public_txt"
                        v-for="(item_, index_) in item.text"
                        :key="index_"
                      >
                        {{ index_ + 1 }}.{{ item_.problem }}
                        <span
                          class="public_txt_handoff"
                          @click="feedbackErr(item, index, item_.problem)"
                          >{{ ">>" + "点击反馈" + "<<" }}
                        </span>
                      </div>
                      <div class="public_txt">
                        如有其他问题，
                        <span
                          class="public_txt_handoff"
                          @click="feedbackErr_other(item, index, '')"
                          >{{ ">>" + "点击这里" + "<<" }} </span
                        >进行反馈
                      </div>
                    </div>
                    <div class="wenxian_" v-if="item.text && item.text.text">
                      <div class="wenxian_li_s">
                        <div class="wenxian_li">
                          <span class="li_tit">需求类型：</span
                          >{{ item.text.text_name }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">需求标题：</span
                          >{{ item.text.title }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">需求内容：</span
                          >{{ item.text.original_text }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">识别内容：</span
                          >{{ item.text.text }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">优先级：</span
                          >{{ item.text.priority }}
                        </div>

                        <div class="wenxian_li">
                          <span class="li_tit">处理状态：</span
                          >{{ item.text.status }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">处理时间：</span
                          >{{ item.text.create_time }}
                        </div>
                      </div>
                      <div class="wenxian_btn_s">
                        <div
                          class="wenxian_btn"
                          @click="copyLink(item.text.down_url)"
                          style="background: #5a79f4"
                          v-if="
                            (item.text.status == '处理成功' ||
                              item.text.status == '相关推荐') &&
                            item.text.down_url
                          "
                        >
                          链接地址
                        </div>
                        <div
                          class="wenxian_btn"
                          style="background: #a2c667"
                          v-if="item.text.read_url"
                        >
                          在线阅读
                        </div>
                        <div
                          class="wenxian_btn"
                          style="background: #ec808d"
                          v-if="item.text.status == '处理成功'"
                          @click="feedbackErr(item, index, '错误反馈')"
                        >
                          错误反馈
                        </div>
                        <div
                          class="wenxian_btn"
                          style="background: #ead775"
                          v-if="
                            item.text.text_name == '中文书' &&
                            item.text.chinese_url
                          "
                        >
                          更多资源
                        </div>
                      </div>
                    </div>
                    <div v-if="typeof item.text == 'string'">
                      <div v-html="item.text"></div>
                    </div>
                  </div>

                  <div
                    v-else
                    class="msg_txt_css"
                    :style="
                      item.problem_answer == 1
                        ? 'background: #D59DB5;width:100%'
                        : ''
                    "
                  >
                    <!--  -->
                    <div
                      class="cite_css"
                      v-if="item.quote_id && result_data[item.quote_id]"
                    >
                      <div class="wenxian_li_s">
                        <div class="wenxian_li">
                          <span class="li_tit">需求类型：</span
                          >{{ result_data[item.quote_id].text.text_name }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">需求标题：</span
                          >{{ result_data[item.quote_id].text.title }}
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="public_txt"
                      style="margin-left: 0px"
                      v-if="item.type == 1 && item.problem_answer == 2"
                    >
                      问题识别为【参考咨询】，如需要进行【文献咨询】可点击问题下方的类型标签切换咨询类型。
                    </div> -->
                    <!-- <div style="margin-left: 0px" v-html="item.text"></div> -->
                    <div
                      style="margin-left: 0px"
                      v-html="item.text.replace(/\n/g, '<br>')"
                    ></div>
                  </div>
                  <!-- <div
                    class="msg_tit"
                    style="line-height: 10px; height: 10px"
                    v-if="item.problem_answer == 1"
                  >
                    <div class="msg_btn" style="width: 100%">
                      <div
                        class="css_type"
                        @click="handoff(item, index)"
                        style="cursor: pointer"
                      >
                        {{ item.type == 1 ? "参考咨询" : "文献咨询" }}
                      </div>
                      <img
                        src="../../../assets/imgs/app/iconPark-switch 1@1x.png"
                        alt=""
                        style="cursor: pointer; width: 22px; height: 18px"
                        @click="handoff(item, index)"
                      />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

            <div
              v-if="submit_or && cue_text_copy"
              class="message_li_css"
              style="justify-content: flex-end"
            >
              <div class="msg_txt_">
                <div class="msg_txt_css" style="background: #d59db5">
                  {{ cue_text_copy }}
                </div>
              </div>
            </div>

            <div v-if="submit_or" class="message_li_css">
              <!--  -->
              <img
                style="
                  width: 40px;
                  height: 40px;
                  position: relative;
                  left: -5px;
                "
                src="../../../assets/imgs/app/robot_icon.png"
                alt=""
              />
              <div class="msg_txt_" style="width: calc(100% - 50px)">
                <div class="msg_tit">
                  <div class="msg_name">图书馆参考咨询员</div>
                  <!-- <div class="msg_btn"></div> -->
                </div>
                <div class="msg_txt_css" style="position: relative">
                  {{ cue_text
                  }}<span v-if="!cue_text" class="typing-caret">_</span>
                </div>
              </div>
            </div>

            <div
              class="up_css"
              v-loading="up_loading"
              element-loading-text="数据加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0)"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="history_search_css" v-if="history_or"></div>
    <van-dialog
      :value="show"
      :title="dialog_tit"
      show-cancel-button
      confirm-button-text="确定"
      cancel-button-text="取消"
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="edit_ruleForm_css"
      >
        <el-form-item label="需求类型" prop="code">
          <el-select
            v-model="ruleForm.code"
            size="medium"
            placeholder="请选择类型"
            popper-class="code_css"
            style="width: 70%"
          >
            <el-option
              v-for="item in objectArray"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="需求内容" prop="text">
          <el-input
            type="textarea"
            v-model="ruleForm.text"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="请填写需求内容..."
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="扩展信息" prop="supplement">
          <el-input
            type="textarea"
            v-model="ruleForm.supplement"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="可添加扩展信息"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
    </van-dialog>
    <van-dialog
      :value="show_remark"
      title="添加备注"
      show-cancel-button
      confirm-button-text="确定"
      cancel-button-text="取消"
      @confirm="onConfirm_remark"
      @cancel="onCancel_remark"
    >
      <div class="remark_box_css">
        <el-input
          type="textarea"
          v-model="remark_text"
          :autosize="{ minRows: 3, maxRows: 5 }"
          placeholder="可添加您的备注"
          style="width: 100%"
        ></el-input>
        <div class="remark_tag_s">
          <p
            style="
              line-height: 30px;
              font-size: 13px;
              color: #555;
              margin-top: 10px;
            "
          >
            常用备注
          </p>
          <el-tag
            size="medium"
            v-for="(item, index) in remark_tag_txt"
            :key="index"
            @click="remark_text = remark_text + item"
            >{{ item }}</el-tag
          >
        </div>
      </div>
    </van-dialog>
  </div>
</template>
  
  <script>
import { Toast } from "vant";
import { Dialog } from "vant";
import wx from "weixin-js-sdk";
import $ from "jquery";
import {
  Identify,
  submit,
  categoryList,
  messagerecordData,
  messageDetail,
  manual,
} from "@/Api/manage";
export default {
  inject: ["reloads"],
  data() {
    return {
      textarea_txt: "",
      //   DOI:10.31486/toj.20.0025\nPMID:34239371\n

      tab_or: true,
      message: "",
      identifyList: [],
      downloadList: [],
      legalArr: [],
      array: [],
      show: false,
      dialog_tit: "编辑需求",
      objectArray: [],
      codeArray: [],
      ruleForm: {
        code: "",
        text: "",
        supplement: "",
      },
      edit_index: 0,
      rules: {
        code: [
          { required: true, message: "请选择需求类型", trigger: "change" },
        ],
        text: [{ required: true, message: "请填写需求内容", trigger: "blur" }],
        // supplement: [
        //   { required: true, message: "请填写扩展信息", trigger: "blur" },
        // ],
      },
      headers: {
        token: localStorage.getItem("token"),
      },
      download_num: 0,
      remark_text: "",
      show_remark: false,
      remark_tag_txt: ["正式版", "补充材料", "查收查引"],
      messageData: [],
      message_count: 0,
      messageQuery: {
        size: 10,
        new_data: "",
        search: "",
        last_id: "",
        direction: "up",
      },
      submit_or: false,
      cue: {
        type: 1,
        text: "",
        organ_code: "BJYKQXKJ001",
      },
      cue_text: "",
      startY: 0,
      isAtTop: true,
      isAtBottom: false,
      down_loading: false,
      down_text: "",
      up_loading: false,
      up_text: "",
      cue_text_copy: "",
      result_data: {},
      scrollHeight: 0,
      cite_data_copy: {},
      merge_ids: [],
      submitList_copy: [],
      fileList: [],
      shake_or: false,
      myDivStyle: {
        height: "calc(100vh - 275px)",
        overflowX: "hidden",
        overflowY: "scroll",
      },
      history_or: false,
    };
  },
  created() {
    // 首页跳转进入
    let searchText = sessionStorage.getItem("searchText");
    if (searchText) {
      this.textarea_txt = searchText;
      sessionStorage.removeItem("searchText");
      this.lookup_sub();
      return;
    }
    console.log(this.$route.query);
    if (this.$route.query.token) {
      localStorage.setItem("token", this.$route.query.token);
      if (this.$route.query.data) {
        // if(this.$route.query.or == 'true'){
        //   this.tab_or = false
        // }else{
        //   this.tab_or = true
        // }
        let text_val = this.$route.query.data.replace(/\|/g, "\n");
        // this.textarea_txt = text_val
        let json_ = {
          text_val: text_val,
          or: this.$route.query.or,
        };
        window.sessionStorage.removeItem("id_data");
        window.sessionStorage.setItem("text_data", JSON.stringify(json_));
        let that = this;
        that.$router.replace(
          {
            path: "/qinli_app",
            query: {
              token: this.$route.query.token,
              reload: new Date().getTime(),
            },
          },
          () => {
            that.reloads(); //刷新页面
          }
        );
      } else if (this.$route.query.id || this.$route.query.date) {
        console.log(
          "this.$route.query",
          this.$route.query.id,
          this.$route.query.date
        );
        let json_ = {
          id: this.$route.query.id ? this.$route.query.id : "",
          date: this.$route.query.date ? this.$route.query.date : "",
        };
        console.log(JSON.stringify(json_));
        window.sessionStorage.removeItem("text_data");
        window.sessionStorage.setItem("id_data", JSON.stringify(json_));
        let that = this;
        setTimeout(function () {
          that.$router.replace(
            {
              path: "/qinli_app",
              query: {
                token: that.$route.query.token,
                reload: new Date().getTime(),
              },
            },
            () => {
              that.reloads(); //刷新页面
            }
          );
        }, 500);
      } else {
        if (window.sessionStorage.getItem("text_data")) {
          let text_ = JSON.parse(window.sessionStorage.getItem("text_data"));
          if (text_.or == "true") {
            this.tab_or = false;
          } else {
            this.tab_or = true;
          }
          this.textarea_txt = text_.text_val;

          this.lookup_sub();
        } else if (window.sessionStorage.getItem("id_data")) {
          let id_data = JSON.parse(window.sessionStorage.getItem("id_data"));
          console.log(id_data);
          if (id_data.id) {
            this.messageQuery.last_id = id_data.id - 0 - 1;
          } else if (id_data.date) {
            this.messageQuery.new_data = id_data.date;
          }
          this.messageQuery.direction = "down";
          console.log(this.messageQuery.last_id);
          this.tab_or = true;
          this.get_messageRecord_history();
        } else {
        }

        // this.get_data();
      }
    } else {
      wx.miniProgram.navigateTo({
        url: "/pages/my/my", // 指定跳转至小程序页面的路径
        success: (res) => {
          console.log(res); // 页面跳转成功的回调函数
        },
        fail: (err) => {
          console.log(err); // 页面跳转失败的回调函数
        },
      });
    }
    this.get_data();
    // Toast('提示内容');
  },
  mounted() {
    if (this.tab_or && !window.sessionStorage.getItem("id_data")) {
      this.get_messageRecord();
    }

    this.$nextTick(() => {
      if (!window.sessionStorage.getItem("id_data")) {
        // this.scrollToBottom();
      }
    });
  },
  watch: {
    messageData() {
      this.$nextTick(() => {
        if (
          !this.messageQuery.last_id &&
          !window.sessionStorage.getItem("id_data")
        ) {
          // this.scrollToBottom();
        }
        //
      });
    },
  },

  methods: {
    adjustDivHeight() {
      this.$nextTick(() => {
        // console.log();
        let textareaHeight =
          this.$refs.textareaInput.$el.querySelector("textarea").scrollHeight;
        // console.log(textareaHeight);
        if (this.textarea_txt == "") {
          textareaHeight = 0;
        } else {
          textareaHeight = textareaHeight - 50;
        }
        console.log(textareaHeight);
        if (textareaHeight >= 86) {
          textareaHeight = 86;
        }
        if (this.cite_data_copy.text) {
          textareaHeight = textareaHeight + 50;
        }

        const newHeight = `calc(100vh - 270px)`;
        console.log(newHeight);
        this.myDivStyle.height = newHeight;
      });
    },
    scrollToBottom() {
      const container = this.$refs.myDiv;
      // console.log('scrollToBottom()')
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    tab_type() {
      this.tab_or = !this.tab_or;
      this.textarea_txt = "";
      console.log(this.tab_or);
      if (this.tab_or) {
        this.messageQuery = {
          size: 10,
          new_data: "",
          search: "",
          last_id: "",
          direction: "up",
        };
        this.get_messageRecord();
      } else {
        this.identifyList = [];
      }
    },
    get_data() {
      categoryList()
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            let resData = res.data.data;
            let keys = Object.keys(resData);
            let arr = [];
            let objArr = [];

            keys.forEach((item) => {
              let obj = {};
              obj.code = item;
              obj.name = resData[item];
              obj.isFirst = false;
              objArr.push(obj);
              arr.push(resData[item]);
            });
            this.array = arr;

            this.objectArray = objArr;
            console.log(this.objectArray);
            this.codeArray = keys;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    lookup_sub() {
      if (this.shake_or) {
        return;
      }
      if (!this.textarea_txt) {
        // Toast.fail("需求为空！");
        this.$message({
          message: "需求为空！",
          type: "warning",
        });
        return;
      }
      if (!this.tab_or) {
        Identify({ text: this.textarea_txt })
          .then((res) => {
            if (res.data.error_code == 0) {
              let newArry = [];
              let downArry = [];
              let count = 0;

              res.data.data.forEach((item) => {
                for (let index = 0; index < item.result_list.length; index++) {
                  let obj = {};
                  obj.text = item.result_list[index].text;
                  obj.code = item.result_list[index].code;
                  obj.name = item.result_list[index].name;
                  obj.supplement = "";
                  if (item.result_list[index].code == "Other") {
                    obj.resultMsg = "请核对需求内容 ！";
                  } else {
                    obj.resultMsg = "";
                  }
                  obj.index = count;
                  obj.priority = "一般";
                  obj.remark = "";
                  obj.highlight = item.highlight;
                  // 单次提交限制10条需求
                  if (obj.code == "Other") {
                    obj.checked = false;
                    obj.disabled = false;
                  } else if (obj.code !== null && downArry.length < 10) {
                    downArry.push(obj);
                    obj.checked = true;
                    obj.disabled = false;
                  } else {
                    obj.checked = false;
                    obj.disabled = true;
                  }
                  newArry.push(obj);
                  count++;
                }
              });
              if (downArry.length == 10) {
                newArry.forEach((item, index) => {
                  if (item.checked == false) {
                    newArry[index].disabled = true;
                  }
                });
              }
              let legalArr = newArry.filter((item) => {
                return this.array.indexOf(item.name) > -1;
              });
              if (downArry.length == 10) {
                // Toast("单次最多提交10条");
                this.$message({
                  message: "单次最多提交10条！",
                  type: "warning",
                });
              }

              this.identifyList = newArry;
              this.downloadList = downArry;
              this.download_num = this.downloadList.length;
              // this.show = true;
              this.legalArr = legalArr;
              this.toView = "identify";
              console.log(this.identifyList);
              if (window.sessionStorage.getItem("text_data")) {
                window.sessionStorage.setItem("text_data", "");
              }
            } else {
              this.message = res.data.msg;
              // Toast.fail(this.message);
              this.$message({
                message: this.message,
                type: "warning",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (!this.cite_data_copy.id) {
          this.cue.quote_id = "";
        }
        this.cue.force_type = "";

        this.consultationAi();
        if (window.sessionStorage.getItem("text_data")) {
          window.sessionStorage.setItem("text_data", "");
        }
      }
    },
    handoff(row, i) {
      if (this.shake_or) {
        return;
      }
      if (row.type == 1) {
        this.cue.type = 2;
        this.cue.force_type = 2;
      } else {
        this.cue.type = 1;
        this.cue.force_type = 1;
      }
      // if (i - 1 >= 0) {
      //   this.cue.text = this.messageData[i - 1].text;

      // }
      // this.cue.text = row.text;
      if (row.problem_answer == 2) {
        console.log();
        if (typeof row.text == "object") {
          this.textarea_txt = "";
          for (var i in row.text) {
            this.textarea_txt = this.textarea_txt + "\n" + row.text[i].text;
          }
          // this.textarea_txt = ;
        } else {
          this.textarea_txt = row.text;
        }
      } else {
        this.textarea_txt = row.text;
      }

      this.cue.quote_id = "";
      // console.log(row);
      // this.get_messageDetail([row.quote_id], "new");
      this.consultationAi();
    },
    consultationAi() {
      if (this.shake_or) {
        return;
      } else {
        this.shake_or = true;
      }
      let that = this;
      that.cue_text = "";
      that.submit_or = true;
      if (that.textarea_txt) {
        that.cue.text = that.textarea_txt;
        that.cue_text_copy = that.textarea_txt;
      }

      let cue = {
        type: that.cue.type,
        text: that.cue.text,
        quote_id: that.cue.quote_id ? that.cue.quote_id : null,
        organ_code: that.cue.organ_code,
        // force_type: that.cue.force_type ? that.cue.force_type : "",
        force_type: 2,
        is_artificial: that.cue.is_artificial ? that.cue.is_artificial : 0,
      };
      that.textarea_txt = "";
      // if (cue.type == 2) {
      //   // DOI:10.31486
      //   cue.text = "DOI:10.31486";
      // }
      // console.log("aaa");

      setTimeout(() => {
        that.scrollToBottom();
      }, 100);
      this.adjustDivHeight();
      that.returnMessageAjax = $.ajax({
        // url: "http://192.168.66.18:8007/api/user/consultation/",
        // url: "https://ql-test.newacademic.net/api/user/consultation/",
        url: "https://xcx.newacademic.net/api/user/consultation/",
        data: JSON.stringify(cue),
        timeout: 50000, // 请求超时时间
        type: "Post",
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          token: localStorage.getItem("token"),
        },
        // dataType: "json",
        processData: false, // 不处理数据
        contentType: false, // 不设置内容类型
        xhrFields: {
          onprogress: function (e) {
            // that.loading_ai = false;
            // console.log(e.currentTarget.response);
            that.cue_text = e.currentTarget.response + "_";

            // return;
            // var end = false;
            // var items = e.currentTarget.response.split("data:");
            // console.log(items);
            // const nonEmptyArray = items.filter((item) => item.trim() !== "");
            // const parsedArray = nonEmptyArray.map((item) =>
            //   item == "end" ? "end" : JSON.parse(item)
            // );
            // // console.log(parsedArray);
            // const contentArray = parsedArray.map((item) => item.content);
            // const newString = contentArray.join("");

            // that.replay_outcome = newString;
            // const idArray = parsedArray.map((item) => item.value);
            // // console.log(idArray);
            // that.conversation_id = idArray.join("");
            // // console.log(that.conversation_id);
            // return;
            // for (var i = 0; i < items.length; i++) {
            //   var item = items[i];
            //   // console.log(item);
            //   // || item.indexOf("data:") < 0
            //   if (item === "") continue;

            //   // item = item.substring(item.indexOf("data:") + 5);
            //   //   console.log(item, item == " DONE");
            //   //   console.log(item == " DONE");
            //   // console.log(item);

            //   if (item == " DONE") {
            //     end = true;
            //     // that.end_show = false;
            //   } else {
            //     var data = JSON.parse(item);
            //     console.log(data);
            //     // content += data.content;
            //     // if (item.indexOf("//NkxAAAAANIA") !== -1) {
            //     //   console.log("a", item);
            //     //   var data = JSON.parse(item);
            //     // } else {

            //     // }
            //   }
            // }
            // // that.scrollBottm();
            // // console.log(content);
            // // that.writing_text = content;
            // // console.log(that.writing_text);
          },
          onload: function (e) {
            console.log(e);
          },
        },
        timeout: 1000 * 60 * 2,

        complete: function (XMLHttpRequest, status) {
          console.log(XMLHttpRequest, status);
          that.cue_text_copy = "";
          that.messageQuery.last_id = "";
          that.messageQuery.direction = "up";
          that.cue.quote_id = "";
          that.cue.is_artificial = "";
          that.cue.force_type = "";
          that.shake_or = false;
          let responseJson = XMLHttpRequest.responseJSON;
          if (responseJson.error_code === 1) {
            that.$message({
              message: responseJson.msg,
              type: "warning",
            });
          }
          that.get_messageRecord();
          if (that.cite_data_copy) {
            that.manual_dispose(
              that.cite_data_copy.text.task_id,
              that.cue.text
            );
          }
        },
      });
    },
    manual_dispose(task_id, msg) {
      let anew_ = {
        task_id: task_id,
        source: 0,
        msg: msg,
      };

      manual(anew_)
        .then((res) => {
          if (res.data.error_code == 0) {
          }
          that.cite_data_copy = {};
        })
        .catch((err) => {
          that.cite_data_copy = {};
        });
    },
    clear_result() {
      this.identifyList = [];
    },
    edit_demand(row, i) {
      console.log(row);
      this.edit_index = i;
      this.ruleForm = {
        code: row.code,
        text: row.text,
        supplement: row.supplement,
      };
      this.dialog_tit = "编辑需求";
      this.show = true;
    },
    merge_demand() {
      this.submitList_copy = [];
      this.merge_ids = [];
      this.identifyList.forEach((item, index) => {
        if (item.checked) {
          this.submitList_copy.push(item);
          this.merge_ids.push(index);
        }
      });
      if (this.submitList_copy.length <= 1) {
        // Toast.fail("请至少选中两项");
        this.$message({
          message: "请至少选中两项",
          type: "warning",
        });
      } else {
        this.ruleForm = {
          code: this.submitList_copy[0].code,
          text: this.submitList_copy.map((item) => item.text).join(""),
          supplement: this.submitList_copy
            .map((item) => item.supplement)
            .join("\n"),
        };
        this.dialog_tit = "需求合并";
        this.show = true;
      }
    },

    remarkEdit(row, i) {
      console.log(row);
      this.edit_index = i;
      this.remark_text = row.remark;
      this.show_remark = true;
    },
    onCancel_remark() {
      this.show_remark = false;
    },
    onConfirm_remark() {
      this.identifyList[this.edit_index].remark = this.remark_text;
      this.show_remark = false;
    },
    checked_change(i) {
      console.log(i, this.identifyList[i].checked);
      if (this.identifyList[i].checked) {
        this.download_num = this.download_num + 1;
      } else {
        this.download_num = this.download_num - 1;
      }
      if (this.download_num >= 10) {
        this.identifyList.forEach((item, index) => {
          if (!item.checked) {
            item.disabled = true;
          }
        });
      } else {
        this.identifyList.forEach((item, index) => {
          item.disabled = false;
        });
      }
    },
    onConfirm() {
      if (this.dialog_tit == "编辑需求") {
        this.identifyList[this.edit_index].code = this.ruleForm.code;
        this.identifyList[this.edit_index].text = this.ruleForm.text;
        this.identifyList[this.edit_index].supplement =
          this.ruleForm.supplement;
        //   if (this.identifyList[this.edit_index].checked) {
        //     this.downloadList[this.edit_index].code = this.ruleForm.code;
        //     this.downloadList[this.edit_index].text = this.ruleForm.text;
        //     this.downloadList[this.edit_index].supplement =
        //       this.ruleForm.supplement;
        //   }

        this.objectArray.forEach((item, index) => {
          if (item.code == this.ruleForm.code) {
            this.identifyList[this.edit_index].name = item.name;
            //   if (this.identifyList[this.edit_index].checked) {
            //     this.downloadList[this.edit_index].name = item.name;
            //   }
          }
        });
        this.show = false;
      } else {
        Dialog.confirm({
          message: "确定合并？",
        })
          .then(() => {
            // this.identifyList.splice(i, 1);
            const filteredArray = this.identifyList.filter(
              (item, index) => !this.merge_ids.includes(index)
            );
            this.identifyList = filteredArray;
            let new_merge = {
              code: this.ruleForm.code,
              text: this.ruleForm.text,
              supplement: this.ruleForm.supplement,
              priority: this.submitList_copy[0].priority,
              name: this.submitList_copy[0].name,
              remark: this.submitList_copy
                .map((item) => item.remark)
                .join("\n"),
              checked: false,
              disabled: false,
              resultMsg: "",
            };
            this.identifyList.push(new_merge);
            this.show = false;
          })
          .catch(() => {
            // on cancel
            this.show = false;
          });
      }
    },
    onCancel() {
      this.show = false;
    },
    del_demand(row, i) {
      Dialog.confirm({
        message: "确定删除吗？",
      })
        .then(() => {
          this.identifyList.splice(i, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    handleSelect() {
      if (this.download_num > 0) {
        let submitList = [];
        this.identifyList.forEach((item, index) => {
          if (item.checked) {
            if (item.code == null || item.code == "null") {
              this.identifyList[index].resultMsg = " ✘ 需求类型错误";
            } else {
              submitList.push({
                id: index,
                type_code: item.code,
                text: item.text, // 需求内容
                supplement: item.supplement,
                original_text: "<b>" + item.text + "</b>", // 高亮文本
                priority: item.priority, //优先、一般
                remark: item.remark, // 备注信息
              });
            }
          }
        });

        let sub = {
          demand: submitList,
          text: this.textarea_txt,
        };
        submit(sub)
          .then((res) => {
            console.log(res);
            console.log(this.identifyList);
            if (res.data.error_code == 0) {
              let sub_data = res.data.data;
              for (var key in sub_data) {
                console.log("key", key);
                let i = submitList[key].id;
                if (sub_data[key].code == 0) {
                  // 提交成功

                  this.identifyList[i].resultMsg = " ✔ 提交成功";
                  // successNum++;
                } else {
                  // 提交失败
                  if (sub_data[key].msg == "今日您中文书的提交数量已达上限") {
                    this.identifyList[i].resultMsg = " ✘ 今日中文书达上限";
                  } else if (
                    sub_data[key].msg == "今日您非中文书的提交数量已达上限"
                  ) {
                    // 达上限时
                    this.identifyList[i].resultMsg = " ✘ 今日已达上限";
                  } else if (sub_data[key].msg == "今日已提交过此需求") {
                    // 重复提交时

                    this.identifyList[i].resultMsg = " ✘ 重复提交";
                  } else if (
                    sub_data[key].msg == "未知原因提交失败" ||
                    sub_data[key].msg == "需求提交过快"
                  ) {
                    // 提交失败时

                    this.identifyList[i].resultMsg = " ✘ 提交失败";
                  } else {
                    this.identifyList[i].resultMsg = " ！" + sub_data[key].msg;
                  }
                }
                this.identifyList[i].disabled = true;
                this.identifyList[i].checked = false;
              }
              console.log("sub_dat", JSON.stringify(sub_data));
              let exists = Object.values(sub_data).some(
                (item) => item.code === 0
              );
              console.log("exists", exists);
              if (exists) {
                this.messageQuery = {
                  size: 10,
                  new_data: "",
                  search: "",
                  last_id: "",
                  direction: "up",
                };
                this.textarea_txt = "";
                this.tab_or = !this.tab_or;
                this.get_messageRecord();
              }
            }
            console.log(this.identifyList);
          })
          .catch((err) => {});
      } else {
        // Toast.fail("需求为空");
        this.$message({
          message: "需求为空",
          type: "warning",
        });
      }
    },
    priority_edit(i) {
      if (this.identifyList[i].priority == "优先") {
        this.identifyList[i].priority = "一般";
      } else {
        this.identifyList[i].priority = "优先";
      }
    },
    get_messageDetail(detail_ids) {
      messageDetail({
        id: detail_ids,
      })
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            console.log(JSON.stringify(res.data.data));
            res.data.data.forEach((item, index) => {
              console.log(item.text[0] == "{");
              if (
                item.type == 2 &&
                item.problem_answer == 2 &&
                (item.text[0] == "{" || item.text[0] == "[")
              ) {
                if (typeof item.text === "string" && item.text) {
                  // let correctedTxt = item.text.replace(
                  //   /([a-zA-Z])'([a-zA-Z])/g,
                  //   "$1＇$2"
                  // );
                  // let jsonString = correctedTxt.replace(/'/g, '"');
                  let jsonString = item.text;
                  try {
                    // 使用 eval 解析字符串
                    item.text = eval("(" + jsonString + ")");
                  } catch (e) {
                    console.error("JSON parsing error:", e);
                  }
                }
              }
            });
            let result = res.data.data.reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {});
            this.result_data = result;
            // console.log(result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_messageRecord_history() {
      messagerecordData(this.messageQuery)
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            res.data.data.data.forEach((item, index) => {
              console.log(item.text[0] == "{");
              if (
                item.type == 2 &&
                item.problem_answer == 2 &&
                (item.text[0] == "{" || item.text[0] == "[")
              ) {
                if (typeof item.text === "string" && item.text) {
                  // let correctedTxt = item.text.replace(
                  //   /([a-zA-Z])'([a-zA-Z])/g,
                  //   "$1＇$2"
                  // );
                  // // 使用正则表达式替换单引号为双引号
                  // let jsonString = correctedTxt.replace(/'/g, '"');
                  let jsonString = item.text;
                  try {
                    // 使用 eval 解析字符串
                    item.text = eval("(" + jsonString + ")");
                  } catch (e) {
                    console.error("JSON parsing error:", e);
                  }
                }
              }
            });
            this.messageData = res.data.data.data;
            this.message_count = res.data.data.count;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_messageRecord() {
      const container = this.$refs.myDiv;
      let prevScrollHeight = 0; // 获取添加新内容前的滚动高度
      let prevScrollTop = 0;
      if (container) {
        if (container.scrollHeight) {
          prevScrollHeight = container.scrollHeight;
        }
        // 获取添加新内容前的滚动高度
        if (container.scrollTop) {
          prevScrollTop = container.scrollTop;
        }
      }

      console.log(this.messageQuery.direction);
      messagerecordData(this.messageQuery)
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            res.data.data.data.forEach((item, index) => {
              // console.log(item.text[0] == "{");
              console.log(this.getTimeText("2024-08-25T18:02:24"));
              item.date_time = this.getTimeText(item.create_time);
              if (
                item.type == 2 &&
                item.problem_answer == 2 &&
                (item.text[0] == "{" || item.text[0] == "[")
              ) {
                console.log(item.text);
                if (typeof item.text === "string" && item.text) {
                  // let correctedTxt = item.text.replace(/([a-zA-Z])'([a-zA-Z])/g, '$1＇$2');
                  // 使用正则表达式替换单引号为双引号

                  // let jsonString = item.text.replace(/'/g, '"');
                  // console.log(jsonString);
                  let jsonString = item.text;
                  try {
                    // 使用 eval 解析字符串
                    item.text = eval("(" + jsonString + ")");
                    console;
                  } catch (e) {
                    console.error("JSON parsing error:", e);
                  }
                }
              }
            });
            if (this.messageQuery.last_id) {
              if (this.messageQuery.direction == "down") {
                this.messageData = [...this.messageData, ...res.data.data.data];
              } else if (this.messageQuery.direction == "up") {
                this.messageData = [...res.data.data.data, ...this.messageData];
              }
            } else {
              this.messageData = res.data.data.data;
              this.$nextTick(() => {
                // console.log('a')
                this.scrollToBottom();
              });
            }

            this.$nextTick(() => {
              console.log(this.messageQuery.direction);
              if (this.messageQuery.direction == "up") {
                // 计算新高度
                const newScrollHeight = container.scrollHeight;
                const heightDifference = newScrollHeight - prevScrollHeight;

                // 调整滚动位置
                container.scrollTop = prevScrollTop + heightDifference;
              }
            });
            if (this.messageQuery.direction) {
              if (res.data.data.data.length == 0) {
                if (this.messageQuery.direction == "down") {
                  this.down_text = "已到底部";
                  // Toast("当前为最新记录");
                } else if (this.messageQuery.direction == "down") {
                  this.up_text = "已到顶部";
                }
              } else {
                if (this.messageQuery.direction == "down") {
                  this.down_text = "";
                } else if (this.messageQuery.direction == "down") {
                  this.up_text = "";
                }
              }
            }

            // this.messageData = res.data.data.data;
            this.message_count = res.data.data.count;

            this.submit_or = false;
            this.up_loading = false;
            this.down_loading = false;
            console.log(this.messageData);
            let quoteIds = [
              ...new Set(
                this.messageData
                  .filter((item) => item.quote_id) // 过滤掉 null 或空值
                  .map((item) => item.quote_id)
              ),
            ]; // 提取 quote_id 并去重

            console.log(quoteIds);
            if (quoteIds.length > 0) {
              this.get_messageDetail(quoteIds);
            }
          }
          console.log(
            this.messageQuery.last_id,
            this.messageQuery.new_data,
            this.messageQuery.last_id || this.messageQuery.new_data
          );

          if (window.sessionStorage.getItem("id_data")) {
            window.sessionStorage.setItem("id_data", "");
          }
          if (this.messageQuery.last_id || this.messageQuery.new_data) {
            // console.log("a");
            this.messageQuery.last_id = "";
            this.messageQuery.new_data = "";
          } else {
            this.$nextTick(() => {
              this.scrollToBottom();
              //
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    firstIndex_s(date) {
      return this.messageData.findIndex((item) => item.date_time === date);
    },
    goHistory() {
      this.history_or = !this.history_or;
      //   this.$router.push({
      //     name: "qinli_history",
      //   });
      // console.log("a");
    },
    backCourse() {
      wx.miniProgram.navigateTo({
        url: "/pages/course/course",
        // 指定跳转至小程序页面的路径
        success: (res) => {
          console.log(res); // 页面跳转成功的回调函数
        },
        fail: (err) => {
          console.log(err); // 页面跳转失败的回调函数
        },
      });
    },
    showExample() {
      this.textarea_txt = `DOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477`;
    },
    onTouchStart(event) {
      const touch = event.touches[0];
      this.startY = touch.clientY;
    },
    onTouchEnd(event) {
      const touch = event.changedTouches[0];
      const endY = touch.clientY;

      const div = this.$refs.myDiv;
      const scrollTop = div.scrollTop;
      const scrollHeight = div.scrollHeight;
      const clientHeight = div.clientHeight;

      this.isAtTop = scrollTop === 0;
      this.isAtBottom = scrollTop + clientHeight === scrollHeight;
      console.log(this.isAtTop, this.isAtBottom);
      if (this.startY > endY + 30 && this.isAtBottom) {
        // 上拉动作
        console.log("Swiped up");
        this.handleSwipeUp();
      } else if (this.startY < endY - 30 && this.isAtTop) {
        // 下拉动作
        console.log("Swiped down");
        this.handleSwipeDown();
      }
    },
    handleSwipeUp() {
      // 处理上拉动作

      if (!this.down_text) {
        this.up_loading = true;
        this.messageQuery.last_id =
          this.messageData[this.messageData.length - 1].id;
        this.messageQuery.direction = "down";

        this.get_messageRecord();
      }
    },
    handleSwipeDown() {
      // 处理下拉动作

      const container = this.$refs.myDiv;
      let prevScrollHeight = 0;
      if (container) {
        prevScrollHeight = container.scrollHeight;
        this.scrollHeight = container.scrollHeight;
      }

      if (!this.up_text) {
        this.down_loading = true;
        this.messageQuery.last_id = this.messageData[0].id;
        this.messageQuery.direction = "up";

        this.get_messageRecord();
        // setTimeout(() => {
        //   container.scrollTo(0, this.scrollHeight + 200); //-30是为了露出最新加载的一行数据
        // }, 100);
      }
    },
    // onScroll() {
    //   const div = this.$refs.myDiv;
    //   const scrollTop = div.scrollTop;
    //   const scrollHeight = div.scrollHeight;
    //   const clientHeight = div.clientHeight;

    //   this.isAtTop = scrollTop === 0;
    //   this.isAtBottom = scrollTop + clientHeight === scrollHeight;
    // },
    onScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop === 0) {
        this.handleSwipeDown();
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        this.handleSwipeUp();
      }
    },

    copyLink(url) {
      window.open(url, "_blank");
      //创建一个 Input标签
      // let oInput = document.createElement("input");
      // oInput.value = url;
      // document.body.appendChild(oInput);
      // oInput.select(); // 选择对象;
      // document.execCommand("Copy");
      // Toast("链接复制成功");

      // oInput.remove();
    },
    feedbackErr(row, i, text) {
      if (this.shake_or) {
        return;
      }
      this.cue = {
        type: 2,
        text: text,
        quote_id: text == "错误反馈" ? row.id : row.quote_id,
        organ_code: "BJYKQXKJ001",
      };
      this.cite_data_copy = {};
      this.consultationAi();
    },
    feedbackErr_other(row, i, text) {
      if (this.shake_or) {
        return;
      }
      this.cue = {
        type: 2,
        text: text,
        quote_id: text == "错误反馈" ? row.id : row.quote_id,
        organ_code: "BJYKQXKJ001",
        is_artificial: 1,
      };
      if (this.result_data[row.quote_id]) {
        this.cite_data_copy = this.result_data[row.quote_id];
      }
      this.adjustDivHeight();
      // this.consultationAi();
    },
    close_icon() {
      this.cue.quote_id = "";
      // this.textarea_txt = "";
      this.cue.is_artificial = 0;
      this.cite_data_copy = {};
      this.adjustDivHeight();
    },
    get_cite(id) {},
    progressfile() {
      // console.log("aa");
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
    },
    succesfile(res) {
      if (res.error_code == 0) {
        if (res.data[1] == null || res.data[1].length == 0) {
          Toast("无法识别");
        } else {
          this.textarea_txt = "";
          res.data[1].map((item) => {
            this.textarea_txt += item.words + "\n";
          });
          this.lookup_sub();
          Toast.clear();
        }
      } else {
        Toast("无法识别");
      }
    },
    or_msg(text_s) {
      // console.log(JSON.stringify(text_s))
      let count = 0;

      for (let key in text_s) {
        if (text_s[key].code === 0) {
          count++;
        }
      }
      return count;
    },

    getTimeText(argument) {
      var timeS = argument;
      var todayT, yestodayT;
      var timeCha = this.getTimeS(timeS);
      console.log(timeCha);
      // 获取今天开始时间 (00:00:00.000) 的毫秒数
      var todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);

      // 从今天开始到现在的时间（以毫秒为单位）
      todayT = Date.now() - todayStart.getTime();

      // 昨天的时间（以毫秒为单位），从昨天开始算起
      yestodayT = todayT + 24 * 60 * 60 * 1000;

      // 提取时间部分 (HH:mm:ss)
      timeS = timeS.slice(-8);

      if (timeCha > yestodayT) {
        argument = argument.replace(/T/, " ");
        return argument.slice(5, 11) + timeS.slice(0, 5);
      }
      if (timeCha > todayT && timeCha < yestodayT) {
        return "昨天" + " " + timeS.slice(0, 5);
      }
      if (timeCha < todayT) {
        return timeS.slice(0, 2) >= 12
          ? "下午" +
              (timeS.slice(0, 2) == 12 ? 12 : timeS.slice(0, 2) - 12) +
              timeS.slice(2, 5)
          : "上午" + timeS.slice(0, 5);
      }
    },

    getTimeS(timeS) {
      // 将时间字符串转换为 Date 对象，考虑到时区问题
      timeS = timeS.replace(/T/, " ");
      timeS = timeS.replace(/[-]/g, "/");
      return Date.now() - new Date(timeS).getTime();
    },

    // 时间戳转年月日
    formatDate(data) {
      let now = new Date(data);
      var year = now.getFullYear(); //取得4位数的年份
      var month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;
      var date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      var hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
      var minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
      var second =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    formatTime(timeStr) {
      // 创建 Date 对象
      let date = new Date(timeStr.replace(/T/, " ").replace(/-/g, "/"));

      // 格式化为 YYYY-MM-DD HH:mm:ss
      let formattedDate =
        date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(date.getDate()).padStart(2, "0") +
        " " +
        String(date.getHours()).padStart(2, "0") +
        ":" +
        String(date.getMinutes()).padStart(2, "0") +
        ":" +
        String(date.getSeconds()).padStart(2, "0");

      return formattedDate;
    },
  },
};
</script>
  
  <style  lang="less" scoped>
.app_box {
  background: #f9f0f4;

  margin: auto;
  display: flex;
  justify-content: center;
  height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.app_css {
  width: 100%;
  // max-width: 700px;
  margin: 0 20%;
  position: relative;
}
.input_css {
  //   width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  // padding: 10px;
  width: calc(100% - 10px);
  padding: 0 10px;
  position: relative;
  right: -20px;
  // margin-top: 20px;
}
.close_css {
  position: absolute;
  right: 60px;
  top: 3px;
  font-weight: 600;
  z-index: 100;
}

.btn_css {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // justify-content: space-between;
  // align-items: center;
  // padding: 3px 0 0 0;
  // width: 30px;

  .btn_tab,
  .btn_icon {
    cursor: pointer;
    // display: flex;
    //
    // align-items: center;
  }
}
.btn_txt {
  font-size: 15px;
  letter-spacing: 1px;
}
.btn_yes {
  font-weight: 600;
  color: #9b4669;
}
.btn_no {
  color: #a8aaae;
}
.btn_icon_img {
  width: 27px;
  height: 27px;
  // margin-left: 10px;
  margin: 0px 5px 5px 15px;
}
.history_css {
  cursor: pointer;
  position: absolute;
  bottom: 3px;
  right: -35px;
}
.top_css {
  // position: absolute;
  // top: 50px;
  width: 100%;
}
.prompt_top_css_ {
  padding: 20px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prompt_text {
  font-weight: 600;
  color: #9b4669;
}
.bottom_css {
  position: absolute;
  bottom: 5px;
  width: 100%;
}
.data_css {
  //   background: white;
  padding: 10px 20px;
  margin: 10px 0;
}
.top_list_css {
  // margin-top: 180px;
  height: calc(100vh - 245px);
  overflow-x: hidden;
  overflow-y: scroll;
}
/deep/ .el-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom_list_css {
  margin-bottom: 180px;
  padding: 0 !important;
}
#myDiv::-webkit-scrollbar {
  // display: none;
}
#myDiv::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
#myDiv::-webkit-scrollbar-thumb {
  //滑块部分
  border-radius: 5px;
  background-color: #cac6c6;
}
#myDiv::-webkit-scrollbar-track {
  //轨道部分
  // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #f9f0f4;
  border-radius: 5px;
}
#myDiv {
  height: calc(100vh - 295px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.tit_txt {
  color: #9b4669;
  font-size: 16px;
  margin-bottom: 10px;
}
.identify_li_css {
  // box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  background: white;
  //   padding: 10px 0 10px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  /deep/ .van-swipe-cell {
    width: 100%;
  }
  /deep/ .van-swipe-cell__wrapper {
    width: 100%;
  }
}
.li_css {
  padding: 10px;
  display: flex;
  align-items: center;
}

.li_box {
  width: calc(100% - 75px);
  .el-tag {
    background: #9b4669;
    color: white;
    text-align: center;
    min-width: 55px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .li_box_txt {
    font-weight: 450;
    // line-height: 25px;
    margin-top: 5px;
    color: #353535;
    letter-spacing: 0.5px;
    white-space: nowrap; //文本强制不换行；
    text-overflow: ellipsis; //文本溢出显示省略号；
    overflow: hidden; //溢出的部分隐藏；
  }
}

.checkbox_col {
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #9b4669;
    border-color: #9b4669;
  }
  /deep/ .el-checkbox__inner:hover {
    border-color: #9b4669;
  }
}
.li_btn {
  display: flex;
  align-items: center;
}
.right_css {
  display: flex;
  height: 100%;
  .right_btn {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: white;
    letter-spacing: 1px;
  }
}
.footer_submit {
  display: flex;
  justify-content: space-between;
  z-index: 999;

  align-items: center;
  padding: 15px 0;
}

.clear_result {
  text-align: center;
  width: 25%;
  padding: 10px 0px;
  box-sizing: border-box;
  color: #fff;
  background: #fa9a99;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}
.sumbit_selected {
  padding: 10px 0px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  background: #b14a75;
  color: #fff;
  border-radius: 5px;
  width: 40%;
  text-align: center;
}

.merge_result {
  color: #b14a75;
  border: #b14a75 solid 1px;
  border-radius: 5px;
  width: 25%;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
/deep/.van-dialog__confirm .van-button__text {
  color: #b14a75 !important;
}
.edit_ruleForm_css {
  padding: 20px;
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
}
/deep/ .el-textarea__inner {
  border-color: #c0c4cc !important;
}
/deep/.el-input__inner {
  border-color: #c0c4cc !important;
}
.identify_li_s {
  height: calc(100vh - 360px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.remark_box_css {
  padding: 20px;
}
.remark_tag_s {
  .el-tag {
    color: #7b7777;
    margin-right: 10px;
  }
}
.message_li_css {
  display: flex;
}
.msg_txt_ {
  max-width: calc(100% - 50px);
}
.msg_txt_css {
  padding: 10px;
  max-width: 100%;
  display: inline-block;
  border-radius: 5px;
  // background: white;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 16px;
}
.msg_tit {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  //   margin: 5px 0;
  line-height: 25px;
  margin-bottom: 5px;
}
.msg_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 150px);
  // background: white;
  position: relative;
  // padding: 0 10px;
  border-radius: 3px;
  color: #b14a75;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  top: -3px;
  .css_type {
    // width: 100%;
    text-align: center;
    margin-right: 5px;
  }
}
.public_txt_handoff {
  color: #b14a75;
  font-weight: 600;
  cursor: pointer;
}
.li_text_css {
  line-height: 20px;
  margin: 5px 0;
}
.tip_css {
  font-size: 12px;
  // position: relative;
  // top: -5px;
}
.down_css,
.up_css {
  height: 30px;
  position: relative;
  top: 10px;
}
.wenxian_li_s {
  .wenxian_li {
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
    white-space: normal;
    .li_tit {
      font-weight: 600;
      color: rgb(41, 41, 41);
    }
  }
}
.wenxian_btn {
  margin: auto;
  text-align: center;
  color: white;
  // width: 50%;
  width: 300px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px auto;
  font-size: 13px;
  cursor: pointer;
}
.cite_css {
  border-left: solid 1px #bdb8b8;
  padding-left: 10px;
  margin-left: 3px;
  font-size: 12px !important;
  margin-bottom: 5px;
  position: relative;
  .wenxian_li {
    line-height: 20px !important;
  }
}
.cite_icon {
  position: absolute;
  right: -5px;
  top: -3px;
  font-weight: 600;
  cursor: pointer;
}
.identify_btn_css {
  background-color: #9b4669;
  padding: 6px 10px;
  border-radius: 3px;
  color: white;
  font-weight: 600;
  font-size: 13px;
  margin-left: 10px;
}
.time_css {
  text-align: center;
  color: #636363;
  font-size: 12px;
  margin: 5px;
}
.typing-caret {
  // position: absolute;
  // right: 0;
  // top: 0;
  // width: 10px;
  display: inline-block;
  // height: 1.2em;
  // background-color: rgb(112, 111, 111);
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.wenxian_txt_css {
  display: inline-block;
  width: calc(100% - 10px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.input_css_pc {
  display: flex;
  // flex-direction: column;
  justify-content: flex-end;
  background: white;
  padding: 0px 0;
  // align-items: center;
}
.textarea_css {
  /deep/ .el-textarea__inner {
    // height: 100px !important;
    border: none;
    margin: 10px 0px !important;
    // max-height: 8em; /* 根据 maxRows 的行高调整，1 行大约 2em */
    // overflow: hidden; /* 隐藏滚动条 */
    resize: none; /* 禁用拖拽调整大小 */
  }
  /deep/ .el-textarea__inner::-webkit-scrollbar {
    width: 5px;
    height: 1px;
  }
  /deep/ .el-textarea__inner::-webkit-scrollbar-thumb {
    //滑块部分
    border-radius: 5px;
    background-color: #b1b0b0;
  }
  /deep/ .el-textarea__inner::-webkit-scrollbar-track {
    //轨道部分
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    border-radius: 5px;
  }
}
</style>
  <style>
.code_css {
  z-index: 5000 !important;
}
</style>