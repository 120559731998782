<template>
  <div class="management_box" v-bind:style="{ minHeight: Height + 'px' }">
    <div class="header">
      <h2>资源管理：</h2>
      <div class="input">
        <el-input
          v-model="searchInput"
          placeholder="请输入检索字段"
          clearable
        ></el-input>
        <el-button size="mini" type="primary" round @click="onSubmit"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="search_field">
      <div class="sifts">
        <div style="width: 81px">文献类型：</div>
        <div class="choose">
          <el-button
            :class="item.case == 1 ? 'chosen' : 'bgcolor'"
            round
            size="mini"
            v-for="(item, index) in type"
            :key="index"
            @click="search_type(item, index)"
            
            >{{ item.book_type }}</el-button
          >
        </div>
      </div>
      <div class="sifts">
        <div style="width: 81px">全部状态：</div>
        <div class="choose">
          <el-button
            :class="item.state == 1 ? 'chosen' : 'bgcolor'"
            round
            size="mini"
            v-for="(item, index) in states"
            :key="index"
            @click="search_states(item, index)"
            >{{ item.task }}</el-button
          >
        </div>
      </div>

      <div class="sifts">
        <div class="choose" style="display: flex">
          <div style="width: 81px; line-height: 28px">检索时间：</div>
          <div>
            <span>开始时间:</span
            ><el-date-picker
              v-model="value1"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              size="mini"
              :picker-options="pickerOptionsStart"
              @change="change('start')"
            >
            </el-date-picker>
            <span>结束时间:</span
            ><el-date-picker
              v-model="value2"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              size="mini"
              :picker-options="pickerOptionsEnd"
              @change="change('end')"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="sifts">
        <div class="choose" style="display: flex">
          <div style="width: 81px">是否已读：</div>
          <el-radio-group v-model="radio" @change="radios(radio)">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="sifts">
        <div class="choose" style="display: flex">
          <div style="width: 81px">需求来源：</div>
          <el-radio-group v-model="origin" @change="origins(origin)">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">青梨</el-radio>
            <el-radio :label="2">微信群</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="sifts">
        <div class="choose" style="display: flex">
          <div style="width: 81px; line-height: 28px">重新筛选：</div>
          <el-button type="danger" size="mini" round @click="reset"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <div class="tables">
      <!-- <div class="ceList">
        <div class="tit">
          <span>需求内容：9787305238505</span>
          <span>需求标题：9787305238505</span>
        </div>
        <div class="type">
          <span>文献类型：中文书</span>
          <span>创建时间：2022-01-17 </span>
          <span>下载链接</span>
          <span>需求状态</span>
        </div>
        <div class="operate">

        </div>
      </div> -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ fontSize: '14px', color: '#606266' }"
        style="width: 100%"
      >
        <el-table-column type="selection" width="35" align="center">
        </el-table-column>
        <!-- <el-table-column label="序号" type="index" :index="indexMethod">
          
        </el-table-column> -->

        <!-- <el-table-column label="是否已读" width="100" align="center">
          <template slot-scope="scope">
            <img
              style="width: 20px"
              v-if="
                scope.row.read == 1 &&
                (scope.row.task_status == 2 || scope.row.task_status == -2)
              "
              src="../.../../../../assets/imgs/确认 (1).png"
              alt=""
            />
            
            <div
              v-if="
                scope.row.read == 0 &&
                (scope.row.task_status == 2 || scope.row.task_status == -2)
              "
              style="
                margin-left: 33px;
                background: #ff6c6c;
                width: 15px;
                height: 15px;
                border-radius: 7.5px;
              "
            ></div>
            <div
              v-if="scope.row.task_status !== 2 && scope.row.task_status !== -2"
              style="
                margin-left: 33px;
                background: #d3d2d2;
                width: 15px;
                height: 15px;
                border-radius: 7.5px;
              "
            ></div>
          </template>
        </el-table-column> -->
        <el-table-column label="时间" align="center" min-width="15%">
          <template slot="header">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                时间排序<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand('time_desc')"
                  >时间降序</el-dropdown-item
                >
                <el-dropdown-item :command="beforeHandleCommand('time_asc')"
                  >时间升序</el-dropdown-item
                >
                <!-- <el-dropdown-item :command="beforeHandleCommand('处理失败',3)"></el-dropdown-item> -->
              </el-dropdown-menu>
              <!-- {{item.create_time | parseTime("{y}-{m}-{d} {h}:{i}")}} -->
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            {{ scope.row.timestamp | parseTime("{y}-{m}-{d} {h}:{i}:{s}") }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="需求来源" align="center" width="200">
          <template slot-scope="scope" style="position: relative">
            <span v-if="scope.row.data_from == 'ql_table'">青梨</span>
            <span v-else>微信</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="text"
          label="需求内容"
          align="left"
          min-width="60%"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <el-popover
                placement="bottom-start"
                @show="contentDetail(scope.row)"
                width="300"
                trigger="hover"
                class="popovers"
              >
                <p style="margin: 0 0 5px 0">
                  <span>需求类型：</span
                  ><span
                    v-if="scope.row.text_name == '中文书'"
                    style="color: #d9a673"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '中文文献'"
                    style="color: #73a6d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '英文文献'"
                    style="color: #7373d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == 'Doi'"
                    style="color: #8676bd"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '链接地址'"
                    style="color: #73d9d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == 'PMID'"
                    style="color: #73d973"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == 'PMCID'"
                    style="color: #73d9a6"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '英文书'"
                    style="color: #d9d973"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '专利'"
                    style="color: #a673d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '标准'"
                    style="color: #d973d9"
                    >{{ scope.row.text_name }}</span
                  >
                  <span
                    v-if="scope.row.text_name == '其他'"
                    style="color: #d973a6"
                    >{{ scope.row.text_name }}</span
                  >
                </p>
                <p v-if="scope.row.title" style="margin: 0 0 5px 0">
                  <span>需求标题：</span><span>{{ scope.row.title }}</span>
                </p>
                <p style="margin: 0 0 5px 0">
                  <span>需求内容：</span><span>{{ scope.row.text }}</span>
                </p>
                <p style="margin: 0 0 5px 0">
                  <span>识别内容：</span
                  ><span v-html="scope.row.original_text"></span>
                </p>
                <!-- <p style="margin: 0 0 5px 0">
                  <span>优&ensp;先&ensp;级：</span
                  ><span>{{ scope.row.priority }}</span>
                </p> -->
                <!-- <p style="margin: 0 0 5px 0">
                  <span>处理状态：</span
                  ><span
                    ><span
                      v-if="scope.row.task_status == 2"
                      style="color: #73b2d9"
                      >处理成功</span
                    >
                    <span
                      v-if="scope.row.task_status == -2"
                      style="color: #d973a6"
                      >处理失败</span
                    >
                    <span
                      v-if="scope.row.task_status == -3"
                      style="color: #d973a6"
                      >已失效</span
                    >
                    <span
                      v-if="scope.row.task_status == -1"
                      style="color: #f56c6c"
                      >已删除</span
                    >
                    <span
                      v-if="scope.row.task_status == 0"
                      style="color: #73d9cb"
                      >待处理</span
                    >
                    <span
                      v-if="scope.row.task_status == 1"
                      style="color: #7373d9"
                      >处理中</span
                    >
                    <span
                      v-if="scope.row.task_status == 3"
                      style="color: #d97373"
                      >人工处理中</span
                    ></span
                  >
                </p> -->
                <p style="margin: 0 0 5px 0">
                  <span>备&emsp;&emsp;注：</span
                  ><span>{{ scope.row.error_msg }}</span>
                </p>
                <!-- <p style="margin: 0 0 5px 0">
                  <span>处理时间：</span
                  ><span>{{ scope.row.create_date }}</span>
                </p> -->

                <span slot="reference" class="oneRow" style="cursor: pointer">
                  <!-- -->
                  <span
                    v-if="
                      scope.row.task_status == 2 || scope.row.task_status == -2 || scope.row.task_status == -3
                    "
                  >
                    <span
                      v-if="scope.row.read == 0"
                      style="
                        display: inline-block;
                        margin-left: 0px;
                        background: #ff6c6c;
                        width: 8px;
                        height: 8px;
                        border-radius: 7.5px;
                        position: relative;
                        top: -9px;
                        left: 3px;
                        z-index: 100;
                      "
                    ></span
                  ></span>

                  <span style="margin-right: 10px">
                    <el-tag
                      size="mini"
                      v-if="scope.row.text_name == '中文书'"
                      style="background: #d9a673; color: white"
                      >中文书</el-tag
                    >

                    <el-tag
                      v-if="scope.row.text_name == '中文文献'"
                      size="mini"
                      round
                      style="background: #73a6d9; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == '英文文献'"
                      size="mini"
                      round
                      style="background: #7373d9; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == 'Doi'"
                      size="mini"
                      round
                      style="background: #8676bd; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == '链接地址'"
                      size="mini"
                      round
                      style="background: #73d9d9; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == 'PMID'"
                      size="mini"
                      round
                      style="background: #73d973; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == 'PMCID'"
                      size="mini"
                      round
                      style="background: #73d9a6; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == '英文书'"
                      size="mini"
                      round
                      style="background: #d9d973; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == '专利'"
                      size="mini"
                      round
                      style="background: #a673d9; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == '标准'"
                      size="mini"
                      round
                      style="background: #d973d9; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.text_name == '其他'"
                      size="mini"
                      round
                      style="background: #d973a6; color: white"
                      >{{ scope.row.text_name }}</el-tag
                    >
                    <el-tag
                      v-if="scope.row.data_from == 'ql_table'"
                      size="mini"
                      round
                      style="background: #62b1f1; color: white"
                      >青梨</el-tag
                    >
                    <el-tag
                      v-if="scope.row.data_from !== 'ql_table'"
                      size="mini"
                      round
                      style="background: #4ac264; color: white"
                      >微信</el-tag
                    >
                    {{ scope.row.text }}
                  </span>
                  <i
                    class="el-icon-warning-outline"
                    style="
                      -webkit-transform: rotate(180deg);
                      -moz-transform: rotate(180deg);
                      -o-transform: rotate(180deg);
                      transform: rotate(180deg);
                    "
                  ></i>
                </span>
              </el-popover>
            </div>
            <!-- <img
            style="display: inline-block;"
              class="readable"
              v-if="scope.row.read == 1 && scope.row.task_status == 2"
              src="../../../assets/imgs/对勾.png"
              alt=""
            /> -->
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="original_text"
          label="文献标题"
          align="left"
          min-width="300"
        >
          <template slot-scope="scope">
            <el-popover
              placement="bottom-start"
              @show="titleDetail(scope.row)"
              trigger="hover"
              class="popovers"
            >
              <p style="width: 200px">{{ scope.row.title }}</p>
              <p slot="reference" class="oneRow" style="cursor: pointer">
                {{ scope.row.title }}
              </p>
            </el-popover>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="文献类型" align="center" width="100">
          <template slot-scope="scope">
            
            <el-button
              v-if="scope.row.text_name == '中文书'"
              size="mini"
              round
              style="background: #d9a673; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '中文文献'"
              size="mini"
              round
              style="background: #73a6d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '英文文献'"
              size="mini"
              round
              style="background: #7373d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == 'Doi'"
              size="mini"
              round
              style="background: #8676bd; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '链接地址'"
              size="mini"
              round
              style="background: #73d9d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == 'PMID'"
              size="mini"
              round
              style="background: #73d973; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == 'PMCID'"
              size="mini"
              round
              style="background: #73d9a6; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '英文书'"
              size="mini"
              round
              style="background: #d9d973; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '专利'"
              size="mini"
              round
              style="background: #a673d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '标准'"
              size="mini"
              round
              style="background: #d973d9; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
            <el-button
              v-if="scope.row.text_name == '其他'"
              size="mini"
              round
              style="background: #d973a6; color: white; width: 90px"
              >{{ scope.row.text_name }}</el-button
            >
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="全部状态" align="center" width="100">
          <template slot-scope="scope" class="stats">
            <span v-if="scope.row.task_status == 2" style="color: #73b2d9"
              >处理成功</span
            >
            <span v-if="scope.row.task_status == -2" style="color: #d973a6"
              >处理失败</span
            >
            <span v-if="scope.row.task_status == -3" style="color: #d973a6"
              >已失效</span
            >
            <span v-if="scope.row.task_status == -1" style="color: #f56c6c"
              >已删除</span
            >
            <span v-if="scope.row.task_status == 0" style="color: #73d9cb"
              >待处理</span
            >
            <span v-if="scope.row.task_status == 1" style="color: #7373d9"
              >处理中</span
            >
            <span v-if="scope.row.task_status == 3" style="color: #d97373"
              >人工处理</span
            >
            <el-button
              size="mini"
              round
              style="background: #73b2d9; color: white; border: none"
              v-if="scope.row.task_status == 2"
              >处理成功</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #d973a6; color: white; border: none"
              v-if="scope.row.task_status == -2"
              >处理失败</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #d973a6; color: white; border: none"
              v-if="scope.row.task_status == -3"
              >已失效</el-button
            >
            <el-button
              size="mini"
              round
              type="danger"
              v-if="scope.row.task_status == -1"
              >已删除</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #73d9cb; color: white; border: none"
              v-if="scope.row.task_status == 0"
              >待处理</el-button
            >
            <el-button
              size="mini"
              round
              style="background: #7373d9; color: white; border: none"
              v-if="scope.row.task_status == 1"
              >处理中</el-button
            >
            <el-button
              size="mini"
              round
              type="primary"
              v-if="scope.row.task_status == 3"
              style="background: #d97373; color: white; border: none"
              >人工处理</el-button
            >
          </template>
        </el-table-column> -->

        <el-table-column label="处理状态/下载链接" align="center" width="180">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              round
              style="background: #73b2d9; color: white; border: none"
              v-if="scope.row.task_status == 2"
              >处理成功</el-button
            > -->
            <div
              v-if="time_or >= scope.row.timestamp"
              style="position: relative"
            >
              <el-button
                size="mini"
                round
                style="
                  background: #909399;
                  color: white;
                  border: none;
                  cursor: auto;
                "
                >已过期</el-button
              >
            </div>
            <div v-else style="display: flex; justify-content: center">
              <el-button
                size="mini"
                round
                style="
                  background: #d973a6;
                  color: white;
                  border: none;
                  cursor: auto;
                "
                v-if="scope.row.task_status == -2"
                @click="links(scope.row)"
                >处理失败</el-button
              >
              <!-- @click="links(scope.row)" -->
              <el-button
                size="mini"
                round
                style="
                  background: #d973a6;
                  color: white;
                  border: none;
                  cursor: auto;
                "
                
                v-if="scope.row.task_status == -3"
                >已失效</el-button
              >
              <el-button
                size="mini"
                round
                type="danger"
                v-if="scope.row.task_status == -1"
                style="cursor: auto"
                >已删除</el-button
              >
              <el-button
                size="mini"
                round
                style="
                  background: #73d9cb;
                  color: white;
                  border: none;
                  cursor: auto;
                "
                v-if="scope.row.task_status == 0"
                >待处理</el-button
              >
              <el-button
                size="mini"
                round
                style="
                  background: #7373d9;
                  color: white;
                  border: none;
                  cursor: auto;
                "
                v-if="scope.row.task_status == 1 || scope.row.task_status == 6"
                >处理中</el-button
              >
              <el-button
                size="mini"
                round
                type="primary"
                v-if="scope.row.task_status == 3"
                style="
                  background: #d97373;
                  color: white;
                  border: none;
                  cursor: auto;
                "
                >二次处理中</el-button
              >
              <div class="pdfdown">
                <el-button
                  size="mini"
                  round
                  class="down_links"
                  style=""
                  v-if="
                    scope.row.task_status == 2 && scope.row.down_url !== '0'
                  "
                  @click="links(scope.row)"
                  >资源详情</el-button
                >
                <el-button
                  size="mini"
                  round
                  class="down_links"
                  style="background: #a2c667"
                  v-if="
                    scope.row.task_status == 5 && scope.row.down_url !== '0'
                  "
                  @click="links(scope.row)"
                  >相似推荐</el-button
                >
                <el-button
                  size="mini"
                  round
                  class="down_links"
                  style=""
                  v-if="scope.row.read_url"
                  @click="preview(scope.row)"
                  >在线阅读</el-button
                >
              </div>
            </div>

            <!-- <el-button
              size="mini"
              round
              v-if="scope.row.task_status !== 2 && scope.row.task_status !== -2"
              disabled
              >链接地址</el-button
            > -->

            <!-- <el-popover
              placement="bottom"
              width="240"
              trigger="click"
              class="popovers"
            > -->
            <!-- <el-button
              slot="reference"
              size="mini"
              round
              style="background: #d973a6; color: white; border: none"
              v-if="scope.row.task_status == -2"
              @click="links(scope.row)"
              >失败原因</el-button
            > -->
            <!-- </el-popover> -->

            <!-- <img
              class="readable"
              v-if="scope.row.read == 0 && scope.row.task_status == 2"
              src="../../../assets/imgs/redSpot.png"
              alt=""
            />
            <img
              class="readable"
              v-if="scope.row.read == 1 && scope.row.task_status == 2"
              src="../../../assets/imgs/对勾.png"
              alt=""
            /> -->
          </template>
        </el-table-column>
        <!--  align="center" width="120" -->
        <el-table-column
          label="操作"
          align="left"
          min-width="30"
          header-align="center"
        >
          <template slot-scope="scope">
            <div class="btns">
              <!-- <el-button
              size="mini"butto提取码
              round
              v-if="
                scope.row.text_code !== 'zhBook' || scope.row.chinese_url == ''
              "
              disabled
              >更多结果</el-button
            > -->
             
              <!-- <el-button
                size="mini"
                round
                disabled
                  v-if="scope.row.task_status == -1"
              
                >删除</el-button
              > -->
              <!-- <el-popconfirm
              title="确定删除吗？"
              v-if="scope.row.task_status !== -1"
              @confirm="remove(scope.row)"
            >
              <el-button
                type="text"
                slot="reference"
                v-if="scope.row.task_status !== -1"
                style="color: #006eff !important; font-weight: 500 !important"
                >删除</el-button
              >
            </el-popconfirm> -->
              <el-button
                size="mini"
                round
                class="down_links1"
                v-if="scope.row.task_status !== -1 && scope.row.task_status !== -3"
                @click="remove(scope.row)"
                >删除</el-button
              >
              <el-button
                size="mini"
                round
                class="down_links"
                v-if="scope.row.task_status == 2"
                @click="feedback(scope.row)"
                >错误反馈</el-button
              >
              <el-button
                size="mini"
                round
                class="down_links"
                v-if="
                  scope.row.text_code == 'zhBook' &&
                  scope.row.chinese_url !== ''
                "
                @click="mores(scope.row.chinese_url)"
                >更多资源</el-button
              >
              <!-- <el-dropdown
              style="margin-left: 20px"
              placement="bottom-start"
              :hide-on-click="false"
              v-if="
                scope.row.task_status == 2 ||
                (scope.row.text_code == 'zhBook' &&
                  scope.row.chinese_url !== '')
              "
            >
              <span
                class="el-dropdown-link"
                style="color: #006eff !important; font-weight: 500 !important"
              >
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="dropdown">
                  <ul>
                    <li
                      v-if="scope.row.task_status == 2"
                      @click="feedback(scope.row)"
                    >
                      错误反馈
                    </li>
                    <li
                      v-if="
                        scope.row.text_code == 'zhBook' &&
                        scope.row.chinese_url !== ''
                      "
                      @click="mores(scope.row.chinese_url)"
                    >
                      更多资源
                    </li>
                  </ul>
                </div>
              </el-dropdown-menu>
            </el-dropdown> -->

              <!-- <el-button
              size="mini"
              round
              style="width: 90px; margin: 0 0 10px 0"
              v-if="scope.row.task_status == -1"
              disabled
              >删除</el-button
            >
            <el-button
              type="danger"
              size="mini"
              round
              style="width: 90px; margin: 0 0 10px 0"
              v-if="scope.row.task_status !== -1"
              @click="remove(scope.row)"
              >删除</el-button
            >
            <el-button
              size="mini"
              round
              style="
                background-color: #e6a23c;
                color: white;
                width: 90px;
                margin: 0 0 10px 0;
              "
              v-if="scope.row.task_status == 2"
              @click="feedback(scope.row)"
              >错误反馈</el-button
            >
            <el-button
              size="mini"
              round
              style="
                background-image: linear-gradient(to bottom, #B14A75, #B14A75);
                color: white;
                width: 90px;
                margin: 0 0 10px 0;
              "
              v-if="
                scope.row.text_code == 'zhBook' && scope.row.chinese_url !== ''
              "
              @click="mores(scope.row.chinese_url)"
              >更多结果</el-button
            > -->
            </div>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="imgs">
            <img
              src="../../../assets/imgs/zanwushuj.png"
              alt=""
              style="width: 50%"
            />
          </div>
        </template>
      </el-table>
    </div>
    <el-dialog
      title="更多中文书"
      :visible.sync="dialogVisible"
      width="580px"
      class="greater"
      @close="closeDialog"
    >
      <ul style="width: 100%">
        <li
          v-for="(item, index) in chBook"
          :key="index"
          style="margin-bottom: 20px; width: 100%"
        >
          <div class="newBook">
            <p
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span>【标题】{{ item.title }}</span
              ><el-button
                v-if="item.is_exitsSSN && !item.type"
                type="primary"
                size="mini"
                @click="tabled(item, index)"
                >提交</el-button
              >
              <el-button v-if="item.type" type="danger" size="mini">{{
                item.type
              }}</el-button>
              <el-button v-if="!item.is_exitsSSN" type="danger" size="mini"
                >暂无资源</el-button
              >
            </p>
            <p>【ISBN】{{ item.isbn }}</p>
            <p>【作者】{{ item.author }}</p>
            <p>【出版时间】{{ item.published_date }}年</p>
            <p>【出版地】{{ item.published_address }}</p>
            <p style="display: inline-block">【内容摘要】{{ item.abstract }}</p>
          </div>
        </li>
      </ul>
    </el-dialog>
    <el-dialog
      @close="closeDialog"
      title="编辑反馈信息"
      :visible.sync="dialogMessage"
      width="580px"
      class="message"
    >
      <!-- <div style="font-size: 18px; margin-bottom: 20px">反馈信息：</div> -->
      <el-form
        :model="anew"
        :rules="rules"
        ref="anew"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item label="任务来源">
          <el-radio-group v-model="anew.source">
            <el-radio :label="0">青梨小程序任务</el-radio>
            <el-radio :label="1">微信群任务</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="反馈类型">
          <el-radio-group v-model="anew.type">
            <el-radio label="链接地址无法访问">链接地址无法访问</el-radio>
            <el-radio label="其他">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="反馈信息" prop="msg" v-if="anew.type == '其他'">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="请输入内容"
            v-model="anew.msg"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          style="margin: 20px; display: flex; justify-content: flex-end"
        >
          <el-button @click="dialogMessage = false">取 消</el-button>
          <el-button type="primary" @click="Resubmit('anew')">确 定</el-button>
        </el-form-item>
      </el-form>

      <!-- <span slot="footer" class="dialog-footer">
        
      </span> -->
    </el-dialog>

    <div class="pag">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        layout="total, prev, pager, next, jumper"
        :total="totalCount"
        v-if="totalCount > 0"
      >
      </el-pagination>
    </div>
    <div class="bottom">
      <div class="footer" v-if="batchs">
        <!-- <el-button size="mini" type="primary" round @click="batch"
          >批量复制</el-button
        > -->
        <el-button size="mini" type="primary" round @click="onClickDown"
          >批量导出</el-button
        >
        <el-button size="mini" type="danger" round @click="deleBatch"
          >批量删除</el-button
        >
      </div>
    </div>
    <el-dialog
      title="链接地址"
      :visible.sync="dialogVisible01"
      :width="form.url.length > 1 ? '650px' : '550px'"
      @close="closeDialog"
    >
      <el-form :model="form">
        <el-form-item
          :label="form.url.length > 1 ? '链接地址' + (index + 1) : '链接地址'"
          :label-width="formLabelWidth"
          v-for="(item, index) in form.url"
          :key="index"
        >
          <!-- <el-input
            id="copy"
            :data-clipboard-text="form.url"
            v-model="form.url"
            disabled
            style="width: 300px"
          ></el-input> -->
          <div class="link_url">
            <span id="copy" class="inputText" style="width: 300px"
              ><span class="urlText" style="">{{ item }}</span></span
            >
            <el-button
              @click="openLink01(item)"
              size="mini"
              round
              style="margin: 0 0 0 10px"
              v-if="form.url.length > 1 && passType !== 2 && passType !== 1"
              >打开链接</el-button
            >
            <el-button
              type="primary"
              @click="copyLink01(item)"
              size="mini"
              round
              v-if="form.url.length > 1 && passType !== 2 && passType !== 1"
              >复制链接</el-button
            >
            <!-- <el-tooltip content="复制链接" placement="bottom" effect="light" v-if="form.url.length > 1 && passType !== 2  && passType !== 1">
              <i @click="copyLink01(item)" class="el-icon-document-copy"></i>
            </el-tooltip>
            <el-tooltip content="打开链接" placement="bottom" effect="light" v-if="form.url.length > 1 && passType !== 2  && passType !== 1">
              <i @click="openLink01(item)" class="el-icon-link"></i>
            </el-tooltip> -->
          </div>
        </el-form-item>
        <el-form-item
          label="文档密码"
          :label-width="formLabelWidth"
          v-if="passType == 1"
        >
          <!-- <el-input
            v-model="form.password"
            disabled
            style="width: 65px"
          ></el-input
          > -->
          <span class="inputText" id="pass">{{ form.password[0] }}</span
          ><span style="color: rgb(115, 171, 223); font-size: 10px"
            >（此密码为您微信 id的后4位）</span
          ><i class="el-icon-document-copy" @click="copyPass"></i>
        </el-form-item>
        <el-form-item
          label="提取码"
          v-if="passType == 2"
          :label-width="formLabelWidth"
        >
          <!-- <el-input
            v-model="form.password"
            disabled
            style="width: 65px"
          ></el-input
          > -->
          <span class="inputText" id="pass">{{ form.password[0] }}</span
          ><i @click="copyPass" class="el-icon-document-copy"></i>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="form.url.length <= 1">
        <el-button @click="openLink">打开链接</el-button>
        <el-button type="primary" @click="copyLink">复制链接</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="失败原因"
      :visible.sync="dialogVisible02"
      :width="'550px'"
      @close="closeDialog"
    >
      <p style="text-indent: 2em; lin-height: 20px">{{ causeFail }}</p>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="openLink">打开链接</el-button> -->
        <el-button type="primary" @click="dialogVisible02 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { Message } from "element-ui";
import {
  demandCheck,
  partChBook,
  manual,
  Deletes,
  batchDelete,
  read,
  unreadNumber,
} from "@/Api/manage";
import { downloadXlsx } from "../../../utils/xlsx";
import Vue from "vue";
import { eventBus } from '../../../eventBus';
export default {
  data() {
    return {
      Height: 0,
      searchInput: "",
      type: [
        {
          book_type: "中文书",
          type: "zhBook",
          case: 0,
        },
        {
          book_type: "英文书",
          type: "enBook",
          case: 0,
        },
        {
          book_type: "DOI",
          type: "Doi",
          case: 0,
        },
        {
          book_type: "PMID",
          type: "PMID",
          case: 0,
        },
        {
          book_type: "PMCID",
          type: "PMCID",
          case: 0,
        },
        {
          book_type: "链接地址",
          type: "Url",
          case: 0,
        },
        {
          book_type: "中文文献 ",
          type: "zhDoc",
          case: 0,
        },
        {
          book_type: "英文文献",
          type: "enDoc",
          case: 0,
        },
        {
          book_type: "专利 ",
          type: "patent",
          case: 0,
        },
        {
          book_type: "标准",
          type: "standard",
          case: 0,
        },
        {
          book_type: "其他",
          type: "Other",
          case: 0,
        },
      ],
      //   type: ["中文书", "英文书", "DOI", "PMID", "PMCID"],
      buttonIndex: 0,
      buttonStates: 0,
      //   states: ["处理成功", "处理中", "待处理", "处理失败"],
      states: [
        {
          task: "处理成功",
          status: 2,
          state: 0,
        },
        {
          task: "处理中",
          status: 1,
          state: 0,
        },
        {
          task: "待处理",
          status: 0,
          state: 0,
        },
        {
          task: "处理失败",
          status: -2,
          state: 0,
        },
        {
          task: "二次处理中",
          status: 3,
          state: 0,
        },
        {
          task: "已失效",
          status: -3,
          state: 0,
        },
        {
          task: "已删除",
          status: -1,
          state: 0,
        },
      ],
      radio: 0,
      origin: 0,
      value1: "",
      value2: "",
      time_or: parseInt(new Date().getTime() / 1000) - 60 * 60 * 24 * 3,
      // 开始时间限制
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.value2) {
            // eslint-disable-next-line max-len
            return (
              // time.getTime() > new Date(this.value2).getTime() ||
              time.getTime() >= new Date(this.value2).getTime() - 86400000
            );
          }
          return time.getTime() <= new Date(this.value2).getTime() - 86400000;
        },
      },
      // 结束时间限制
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.value1) {
            return time.getTime() < new Date(this.value1).getTime();
          }
          return time.getTime() <= new Date(this.value1).getTime() - 86400000;
        },
      },
      tableData: [],
      batchs: false,
      Types: ["all"],
      condition: ["all"],
      needsList: {
        page: 1,
        size: 10,
        sort_by: "time_desc",
        con_type: [],
        status: [],
        start_date: "",
        end_date: "",
        content: "",
        read: 0,
        source: 0,
      },
      start_time: "",
      end_time: "",
      currentPage: 1, // 当前页码
      totalCount: null, //总条数
      dialogVisible: false,
      chBook: [],
      partch: {},
      dialogMessage: false,
      anew: {
        task_id: "",
        msg: "",
        source: 0,
        type: "其他",
      },
      multipleSelection: [],
      rules: {
        msg: [
          { required: true, message: "请输入反馈消息", trigger: "blur" },
          { min: 3, message: "反馈信息内容至少10字符", trigger: "blur" },
        ],
      },
      intervalId: null,
      statusType: "",
      dialogVisible01: false,
      form: {
        url: [],
        password: [],
      },
      formLabelWidth: "120px",
      passType: 0,
      dialogVisible02: false,
      causeFail: "",
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 170; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 170;
    };
    // 监听当前页面 显示状态
    window.addEventListener("visibilitychange", this.hanldeVisiblityChange);

    // 当页面被销毁时 移除监听
    this.$on("hook:beforeDestroy", () => {
      // console.info("ws 我被销毁了, 移除监听>>>");
      window.removeEventListener(
        "visibilitychange",
        this.hanldeVisiblityChange
      );
    });
  },
  created() {
    // console.log(this.time_or)
    // let time =
    // 		console.log(time)
    let date = new Date();
    // console.log(date);
    var dateStare = new Date().setDate(new Date().getDate() - 3);
    // console.log(dateStare);
    // var date= new Date(Date.parse(date));
    let year1 = new Date(dateStare).getFullYear(); // 年
    let year = date.getFullYear(); // 年

    let month = date.getMonth() + 1; // 月
    month = month < 10 ? "0" + month : month;
    let month1 = new Date(dateStare).getMonth() + 1; // 月
    month1 = month1 < 10 ? "0" + month1 : month1;
    let day = date.getDate(); // 日
    day = day < 10 ? "0" + day : day;
    let day1 = new Date(dateStare).getDate(); // 日
    day1 = day1 < 10 ? "0" + day1 : day1;
    // console.log(day)
    // if(month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12"){
    //   if(day == "01"){
    //     day = 33
    //   }
    //   if(day == "02"){
    //     day = 33
    //   }
    // }
    // if (day == "01") {
    //   let month1 = month - 1;
    //   let day1 = 27;
    //   this.value1 = year + "-" + month1 + "-" + day1;
    // }
    this.value1 = year1 + "-" + month1 + "-" + day1;
    this.value2 = year + "-" + month + "-" + day;
    // console.log(this.value1);
    // console.log(this.value2);
    this.needsList.start_date = this.value1;
    this.needsList.end_date = this.value2;
    // this.needsList.start_date = '2024-08-29';
    // this.needsList.end_date = '2024-08-29';
    this.start_time = this.value1;
    this.end_time = this.value2;
    if (this.$route.query.origins) {
      this.origin = Number(this.$route.query.origins);
    }
    this.demandList();
    this.dataRefreh();
  },
  methods: {
    //预览
    preview(e) {
      var task_id = e.read_url.split("task_id=");
      console.log();
      this.$router.push({
        name: "Read",
        query: {
          task_id: task_id[1],
        },
      });
    },
    closeDialog() {
      // this.needsList.page = 1;
      this.demandList();
    },
    // 选择需求状态
    beforeHandleCommand(command) {
      return {
        command: command,
      };
    },
    // 下拉框选择
    handleCommand(command) {
      // console.log(command)
      this.needsList.sort_by = command.command;
      this.needsList.page = 1;
      this.demandList();
    },
    //   需求列表
    demandList() {
      this.needsList.con_type = this.Types;
      // if (this.condition.length > 0) {
      this.needsList.status = this.condition;
      // } else {
      //   this.needsList.status = ["all"];
      // }
      let que = {
        uc: localStorage.getItem("code"),
      };
      this.currentPage = this.needsList.page
      demandCheck(this.needsList, que)
        .then((res) => {
          // console.log(res);

          // if (this.needsList.read == 2) {
          //   let read_or = [];
          //   res.data.data.data.forEach((item, index) => {
          //     if (item.task_status == 2 || item.task_status == -2) {
          //       read_or.push(item);
          //     }
          //   });
          //   this.tableData = read_or;
          //   this.totalCount = read_or.length;
          // } else {
            this.tableData = res.data.data.data;
            this.totalCount = res.data.data.total;
          // }
          // this.statusType = res.data.data.data_from;
          this.notNumbers();
        })
        .catch((err) => {});
    },
    // 序号
    indexMethod(index) {
      return index + 1;
    },
    onSubmit() {
      this.needsList.content = this.searchInput;
      this.reset();
      this.needsList.page = 1;
      this.demandList();
      // console.log('submit!');
    },
    // 筛选类型
    search_type(item, i) {
      // console.log(item, i)
      // this.type[i].case = 1;
      if (this.type[i].case == 0) {
        this.type[i].case = 1;
        // console.log(this.type);
        let index = this.Types.indexOf("all");
        if (index > -1) {
          //大于0 代表存在，
          this.Types.splice(index, 1); //存在就删除
        }
        this.Types.push(item.type);
        // console.log(this.Types);
      } else {
        this.type[i].case = 0;
        let index = this.Types.indexOf(item.type);
        if (index > -1) {
          //大于0 代表存在，
          this.Types.splice(index, 1); //存在就删除
        }
        // console.log(this.Types);
        if (this.Types.length == 0) {
          this.Types = ["all"];
        }
      }
      this.needsList.page = 1;
      this.demandList();
    },
    // 筛选状态
    search_states(item, i) {
      //
      //   console.log(this.states[i].state)
      //   console.log(typeof this.states[i].state)
      // this.condition = [];

      if (this.states[i].state == 0) {
        this.states[i].state = 1;
        let index = this.condition.indexOf("all");
        if (index > -1) {
          //大于0 代表存在，
          this.condition.splice(index, 1); //存在就删除
        }
        this.condition.push(item.status);
        // console.log(this.states);
      } else {
        this.states[i].state = 0;
        let index = this.condition.indexOf(item.status);
        if (index > -1) {
          //大于0 代表存在，
          this.condition.splice(index, 1); //存在就删除
        }
        if (this.condition.length == 0) {
          this.condition = ["all"];
        }
      }
      // console.log(this.condition);
      this.needsList.page = 1;
      this.demandList();
    },
    // 是否已读
    radios(label) {
      // console.log(label)
      // if (label == 1) {
      //   this.needsList.read = 1;
      // } else if (label == 2) {
      //   this.needsList.read = 0;
      // } else {
      //   this.needsList.read = null;
      // }
      this.needsList.read = label;
      this.needsList.page = 1;
      this.demandList();
    },
    origins(label) {
      this.needsList.source = label;
      this.needsList.page = 1;
      this.demandList();
    },
    // 重置
    reset() {
      this.type.forEach((item) => {
        item.case = 0;
      });
      this.states.forEach((item) => {
        item.state = 0;
      });
      this.condition = [];
      this.Types = ["all"];
      this.needsList.start_date = this.start_time;
      this.needsList.end_date = this.end_time;
      this.value1 = this.start_time;
      this.value2 = this.end_time;
      this.needsList.page = 1;
      this.needsList.read = 0;
      this.radio = 0;
      this.needsList.source = 0;
      this.demandList();
    },
    //显示问题详情列表
    contentDetail(row) {
      // console.log(row);
    },
    //显示标题详情
    titleDetail(row) {
      // console.log(row);
    },
    // 多选  批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val);
      if (val.length > 0) {
        this.batchs = true;
      } else {
        this.batchs = false;
      }
    },
    batch() {
      this.batchs = false;
    },
    // 导出exls表格
    onClickDown() {
      let datalist = [];
      datalist.push(["文书类型", "标题", "下载地址"]);
      this.multipleSelection.forEach((item) => {
        datalist.push([item.text_name, item.text, item.down_url]);
      });
      downloadXlsx(datalist, "需求数据统计.xlsx");
      this.batchs = false;
    },
    // 批量删除
    deleBatch() {
      let delete_id = [];
      // console.log(this.multipleSelection);
      this.multipleSelection.forEach((item) => {
        // delete_id.push(item.task_id);
        let md = {
          task_id: item.task_id,
          source: item.data_from == "ql_table" ? 0 : 1,
        };
        Deletes(md)
          .then((res) => {
            // console.log(res);
            this.demandList();
            // this.$message({
            //   message: res.data.msg,
            //   type: "success",
            // });
          })
          .catch((err) => {
            console.log(err);
          });
      });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      // this.demandList();
      // let taskId = {
      //   task_id: delete_id,
      // };
      // batchDelete(taskId)
      //   .then((res) => {
      //     this.$message({
      //       message: res.data.msg,
      //       type: "success",
      //     });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      this.batchs = false;
    },
    change(type) {
      // console.log(i);start_date
      // console.log(this.value1); // 确定时间范围之后触发的函数
      if (type == "start") {
        this.needsList.start_date = this.value1;
      }
      if (type == "end") {
        this.needsList.end_date = this.value2;
      }
      this.needsList.page = 1;
      this.demandList();
    },
    // 链接地址
    links(row) {
      // console.log(row.read);
      if (row.read == "0") {
        let id = {
          task_id: [row.task_id],
          source: row.data_from == "ql_table" ? 0 : 1,
        };
        read(id)
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        this.demandList();
      }
      // console.log(row.down_url)
      // row.down_url =
      //   "标题:  __中国禁毒历程_p570_.pdf\nURL:  http://cb1.new66.net/#/index?filename=601b465dad0a4d3fc845ef462f30905004876eefa702853291695295ba9ba79853db28bc0821439026bc6d1c65950250e45303bc3c021279529cd0af36f28b5c\n======================\n";
      // row.down_url =
      //   "http://s.dic.cool/S/dXGB8BXM文档密码：1m22（此密码为您微信 id的后4位）";
      // console.log(row.down_url);
      // if (row.text_code == "enDoc" || row.text_code == "enBook") {
      //   if (row.down_url.indexOf(",") !== -1) {
      //     let urlArray = row.down_url.split(",");
      //     this.form.url = [];
      //     urlArray.forEach((item, index) => {
      //       this.form.url.push(item);
      //     });
      //   } else {
      //     this.form.url = [row.down_url];
      //   }
      // } else
      if (row.task_status !== -2) {
        this.dialogVisible01 = true;

        if (row.down_url.indexOf("文档密码：") !== -1) {
          // alert("Hi,Tara");
          this.passType = 1;
          let pass = row.down_url.split("文档密码：");
          // this.form.url = pass[0];
          this.form.url.push(pass[0]);
          console.log(pass);
          this.form.password.push(pass[1].slice(0, 4));
        } else if (row.down_url.indexOf("提取码：") !== -1) {
          // alert("Hi,Tara");
          this.passType = 2;
          let pass = row.down_url.split("提取码：");
          // this.form.url = pass[0];
          this.form.url.push(pass[0]);
          console.log(pass);
          // this.form.password = pass[1].slice(0, 4);
          this.form.password.push(pass[1].slice(0, 4));
        }
        // else if (row.down_url.indexOf("\n") !== -1) {
        //   // alert("Hi,Tara");
        //   // this.passType = 2;
        //   let pass = row.down_url.split("");
        //   let urlList = row.down_url.split('\n')
        //   urlList.forEach((item)=>{
        //     if (item.indexOf("http:") !== -1){
        //       this.form.url = item.substring(item.lastIndexOf("http:"));
        //     }
        //   })
        //   console.log(urlList)
        //   // this.form.url = pass[0];
        //   // console.log(pass);
        //   // this.form.password = pass[1].slice(0, 4);
        // }
        else {
          this.passType = 0;
          console.log();
          if (row.down_url.indexOf(",") !== -1) {
            let urlArray = row.down_url.split(",");
            this.form.url = [];
            urlArray.forEach((item, index) => {
              let urls_ = "";
              urls_ = item.replace(/\n/g, "      ");
              console.log(urls_);
              if (urls_.indexOf("      ") !== -1) {
                // alert("Hi,Tara");
                // this.passType = 2;
                // let pass = row.down_url.split("");
                let urlList_ = urls_.split("      ");
                console.log(urlList_);

                urlList_.forEach((item_) => {
                  if (item_.indexOf("http:") !== -1) {
                    this.form.url.push(
                      item_.substring(item_.lastIndexOf("http:"))
                    );
                    // this.form.url = item.substring(item.lastIndexOf("http:"));
                  }
                });
                console.log(urlList_);
                // this.form.url = pass[0];
                // console.log(pass);
                // this.form.password = pass[1].slice(0, 4);
              } else {
                this.form.url.push(item);
              }
            });
          } else {
            let urls = "";
            urls = row.down_url.replace(/\n/g, "      ");
            console.log(urls);
            if (urls.indexOf("      ") !== -1) {
              // alert("Hi,Tara");
              // this.passType = 2;
              // let pass = row.down_url.split("");
              let urlList = urls.split("      ");
              console.log(urlList);
              this.form.url = [];
              urlList.forEach((item) => {
                if (item.indexOf("http:") !== -1) {
                  this.form.url.push(item.substring(item.lastIndexOf("http:")));
                  // this.form.url = item.substring(item.lastIndexOf("http:"));
                }
              });
              console.log(urlList);
              // this.form.url = pass[0];
              // console.log(pass);
              // this.form.password = pass[1].slice(0, 4);
            } else {
              this.form.url = [];
              this.form.url.push(row.down_url);
            }
          }
          return;
        }

        // window.open(row.down_url, "_blank");
      }
      if (row.task_status == -2) {
        this.dialogVisible02 = true;
        if (row.error_msg == "") {
          this.causeFail = "暂未找到失败原因！！";
          // this.$message("工作人员没有填写失败原因哦！！");
        } else {
          this.causeFail = row.error_msg;
          // this.$message.error("失败原因：" + row.error_msg);
        }
      }
    },
    // 打开链接
    openLink() {
      window.open(this.form.url[0], "_blank");
    },
    openLink01(links) {
      window.open(links, "_blank");
    },
    // 复制链接
    copyLink() {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = this.form.url[0];
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    // 复制链接
    copyLink01(item) {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = item;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    // 复制密码
    copyPass() {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = this.form.password[0];
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    notNumbers() {
      unreadNumber()
        .then((res) => {
          // console.log(res);
          // if (res.data.data.num > 0) {
          // this.$emit("getTreeData", res.data.data.num);
          // }
          eventBus.$emit('updateNum', res.data.data.num);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 更多结果
    mores(link) {
      // this.dialogVisible = true;
      // this.chBook = [
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      //   {
      //     _id: "DX16330361",
      //     title: "结构动力学  理论及其在地震工程中的应用",
      //     ssn: "14113842",
      //     author: "（美）安尼尔·乔普拉（ANILK.CHOPRA）；谢礼立，吕大刚",
      //     published_address: "北京：高等教育出版社",
      //     published_date: "2016",
      //     abstract:
      //       "本书对结构动力学的基本知识、基础理论给予了系统、全面的阐述，内容深入浅出、循序渐进，在系统介绍基本理论知识的同时，密切结合地震工程的实践，对理论研究和工程应用，乃至抗震设计规范中的一些重要的结构动力学问题都给予了重点介绍，充分体现了理论联系实际的风格。",
      //     isbn: "7040459906",
      //     is_provide: true,
      //     is_sensitive: false,
      //     is_Html: false,
      //     is_exitsSSN: true,
      //   },
      // ];
      this.$http
        .get(link, {})
        .then((res) => {
          // console.log(res);
          if (res.data.error_code == 0) {
            this.chBook = res.data.data;
            if (this.chBook.length > 0) {
              this.dialogVisible = true;
            } else {
              Message({
                message: "暂无更多数据！！",
                type: "warning",
              });
            }
            // Message({
            //   message: res.data.msg,
            //   type: "success",
            // });
          }
          if (res.data.error_code == 1) {
            Message({
              message: res.data.msg,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交中文书
    tabled(part, index) {
      // console.log(part);
      this.partch = {
        demand: [
          {
            id: 1,
            type_code: "zhBook",
            text: part.title,
            original_text: "<b>" + part.title + "</b>",
            priority: "一般",
            title: part.title,
            ssn: part.id,
          },
        ],
      };
      partChBook(this.partch)
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            if (res.data.data[1].msg == "需求提交成功") {
              this.$message({
                message: res.data.data[1].msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.data[1].msg,
                type: "warning",
              });
            }

            Vue.set(this.chBook[index], "type", res.data.data[1].msg);
            console.log(this.chBook);
            // this.dialogVisible = false;
            // this.demandList();
          }
          // if (res.data.error_code == 1) {
          //   this.$message({
          //     message: res.data.msg,
          //     type: "warning",
          //   });
          // }
        })
        .catch((err) => {
          // console.log(err);
          // this.$message({
          //   message: "提交失败",
          //   type: "warning",
          // });
        });
    },
    // 错误反馈
    feedback(row) {
      this.anew.msg = "";
      // console.log(id);

      if (row.data_from == "ql_table") {
        this.anew.source = 0;
      } else {
        this.anew.source = 1;
      }
      this.anew.type = "其他";
      this.dialogMessage = true;
      this.anew.task_id = row.task_id;
    },
    // 重新提交任务
    Resubmit(anew) {
      this.anew.msg = this.anew.msg.replace(/\s*/g, ""); // 去除字符串内所有的空格
      // console.log(this.anew);
      // return;
      this.$refs[anew].validate((valid) => {
        if (valid) {
          // console.log(this.anew)
          // return
          let anew_ = {
            task_id: this.anew.task_id,
            source: this.anew.source,
            msg: this.anew.type == "其他" ? this.anew.msg : this.anew.type,
          };
          // console.log(anew_)
          // return
          manual(anew_)
            .then((res) => {
              // console.log(res);
              // if (res.data.error_code == 1) {
              //   this.$message({
              //     message: res.data.msg,
              //     type: "warning",
              //   });
              // }
              if (res.data.error_code == 0) {
                this.dialogMessage = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              }
              this.demandList();
            })
            .catch((err) => {
              // console.log(err);
              // this.$message({
              //   message: err,
              //   type: "warning",
              // });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 单个删除需求
    remove(row) {
      // console.log(id);
      let md5 = {
        task_id: row.task_id,
        source: row.data_from == "ql_table" ? 0 : 1,
      };
      // console.log(md5);
      Deletes(md5)
        .then((res) => {
          // console.log(res);
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.demandList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 分页跳转
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.needsList.page = val;
      this.demandList();
      // this.currentPage = val;
      // window.scrollTo(0, 0);
      // this.loadNode = true;
      // this.loading_box = true;
      // this.caseList();
    },
    // 每页显示条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      // this.pageSize = val;
      // this.loadNode = true;
      // this.loading_box = true;
      // this.caseList();
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        // console.log("刷新" + new Date());
        this.demandList(); //加载数据函数
      }, 30000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    hanldeVisiblityChange() {
      if (document.visibilityState === "hidden") {
        // console.log("ws  hidden>>>关闭定时器");
        this.clear();
      } else if (document.visibilityState === "visible") {
        // console.log("ws  visible>>>启动定时器");
        this.dataRefreh();
      }
    },
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
  },
};
</script>

<style lang='less' scoped>
.management_box {
  // height: calc(100vh - 210px);
  // overflow-x: hidden;
  // overflow-y: scroll;
  background: white;
  // position: relative;
  // min-height: 727px;
  padding: 30px 10%;
  // display: flex;
  // justify-content: center;
  // .main_box{
  //   max-width: 1500px;
  // }
  .header {
    display: flex;
    // justify-content: space-between;
    // width: 80%;
    max-width: 1400px;
    margin: auto;
    min-width: 600px;
    height: 50px;
    line-height: 50px;
    h2 {
      font-size: 18px;
      // border-left: rgb(243, 102, 102) 3px solid;
      // padding-left: 5px;
    }
    .input {
      // width: 380px;
      line-height: 50px;
      .el-input {
        width: 375px;
        margin-right: 10px;

        /deep/ .el-input__inner {
          height: 30px;
          line-height: 30px;
          border-radius: 15px;
        }
      }
    }
    .el-button--mini {
      width: 90px;
    }
  }
  .search_field {
    max-width: 1400px;
    margin: auto;
    .sifts {
      display: flex;
      margin: 15px 0;
      font-weight: 600;
      .choose {
        width: 800px;
      }
      .el-button--mini {
        width: 90px;
        margin: 0 10px 10px 0;
      }
      span {
        font-weight: normal;
      }
      .bgcolor {
        background-color: #f5f3ff;
        color: #aaa;
      }
      .chosen {
        // border: 1px solid #806fde;
        // background: none;
        color: #ffffff;
        background-image: linear-gradient(to bottom, #B14A75, #B14A75);
      }
      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 125px;
        margin: 0 20px 0 10px;
      }
      /deep/ .el-input__inner {
        border-radius: 14px;
        // background-color: #f5f3ff;
        background-image: linear-gradient(to bottom, #B14A75, #B14A75);
        color: rgb(255, 255, 255);
      }
    }
  }
  .tables {
    max-width: 1400px;
    margin: auto;
    // margin: 30px 0;
    .el-button--mini {
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    .footer {
      height: 50px;
      line-height: 50px;
      width: 80%;
      // background: rgba(0, 0, 0, 0.5);
      // position: fixed;
      // bottom: 10px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-button--mini {
        display: inline-block;
        width: 90px;
        height: 30px;
      }
    }
  }
  .pag {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.greater {
  min-width: 650px;
  height: 800px;
  ul::-webkit-scrollbar {
    display: none;
  }
  ul {
    max-height: 500px;
    display: inline-block;
    overflow-x: hidden;
    overflow-y: scroll;
    li {
      display: inline-block;
      padding: 5px;
      margin-bottom: 20px;
      .newBook {
        p {
          height: 20px;
          line-height: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
.stats {
  .el-button--mini {
    width: 90px;
  }
}
.readable {
  width: 25px;
  position: absolute;
  top: 0px;
  right: 10px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  // background-color: #806fde;
  background-image: linear-gradient(to right, #6861c9, #8067dc);
  color: #fff;
}
.el-popover {
  p {
    display: inline-block;
    display: flex;
    span {
      display: inline-block;
      min-width: 70px;
    }
  }
}
.oneRow {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // width: 100px;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
}
.ceList {
  .tit {
    span {
      display: inline-block;
    }
  }
  .type {
    span {
      display: inline-block;
    }
  }
}

.inputText {
  display: inline-block;
  padding: 0px 15px;
  border-radius: 5px;
  height: 40px;
  background-color: #f5f7fa;
  span {
    // display: inline-block;
    margin: 0 10px 0 0;
  }
}
.el-icon-document-copy {
  display: inline-block;
  margin: 0 10px;
}
.el-icon-document-copy:hover {
  color: #67badb;
  cursor: pointer;
}
.urlText::-webkit-scrollbar {
  display: none;
}
.urlText {
  max-width: 270px;
  display: inline-block;
  // overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}
.btns {
  margin: 0 auto;
  display: flex;
  // justify-content: center;
}
.down_links {
  // margin-top: 8px;
  background-image: linear-gradient(to bottom, #B14A75, #B14A75);
  color: white;
  z-index: 99;
}
.down_links1 {
  background-image: linear-gradient(to bottom, #f56c6c, #f56c6c);
  color: white;
  z-index: 99;
}
.dropdown {
  width: 100px;
  ul {
    li {
      text-align: center;
      line-height: 30px;
    }
    li:hover {
      cursor: pointer;
      color: #409eff;
      background: #ecf5ff;
      // color: #4ac264;
      // background: #909399;
    }
  }
}
.link_url {
  display: flex;
  align-items: center;
  span {
    display: inline-block;
  }
  i {
    display: inline-block;
    cursor: pointer;
  }
}
.pdfdown {
  display: flex;
  justify-content: center;
}
</style>

